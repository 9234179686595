import { serializeObject } from '@/utils/common'

export default {
  async getSelectedClient({ commit }, payload = {}) {
    const { id } = payload

    // return if id is either undefined
    if (!id) {
      return
    }

    commit('SET_LOADING', true)

    try {
      const { client: c } = await this.$axios.$get(`/customers/${id}`)
      const client = c || {}
      commit('tickets/ATTACH_DATA', { client }, { root: true })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async updateClientProfile({ commit }, payload = {}) {
    const { id, params } = payload
    commit('SET_LOADING', true)
    try {
      const { data } = await this.$axios.put(`/customers/${id}`, {
        clientId: id,
        email: (params.email || '').trim(),
        displayName: (params.name || '').trim(),
        location: params.location,
        company: (params.company || '').trim(),
        jobTitle: (params.jobTitle || '').trim(),
        groups: params.groups || []
      })
      commit('SET_LOADING', false)
      return data
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      commit('SET_LOADING', false)
    }
  },

  async getPrevTickets({ commit }, payload = {}) {
    const { id } = payload
    try {
      const { tickets } = await this.$axios.$get(`/customers/${id}/tickets`)

      const prevTickets = tickets
        ? tickets.sort((a, b) => new Date(b.UpdatedAt) - new Date(a.UpdatedAt))
        : {}

      commit('tickets/ATTACH_DATA', { prevTickets }, { root: true })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async getNotes({ commit }, payload = {}) {
    const { clientId } = payload
    try {
      const { notes } = await this.$axios.$get(`/customers/${clientId}/notes`)

      const clientNotes = notes ? notes.reverse() : {}
      commit('tickets/ATTACH_DATA', { clientNotes }, { root: true })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async addNote({ commit }, payload = {}) {
    const { clientId, remarks } = payload
    try {
      await this.$axios.post(`/customers/${clientId}/notes`, {
        clientId,
        remarks
      })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async getGroupsByClientId({ commit }, payload = {}) {
    const { id: clientId } = payload
    try {
      const { data } = await this.$axios.get(`/customers/${clientId}/groups`)
      const { groups } = data

      commit('tickets/ATTACH_DATA', { clientContactGroups: groups || [] }, { root: true })

      return data
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async getGroupsByTenantId({ commit }, payload = {}) {
    const { tenantId, query } = payload

    const qs = serializeObject(query)

    try {
      const { data } = await this.$axios.get(`/tenants/${tenantId}/groups?${qs}`)

      return data
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  }
}
