import mimeTypes from 'mime-types'
export default {
  async uploadFile({ commit, dispatch }, data = {}) {
    const {
      file, // either file or blob
      category = 'profiles',
      name = 'profile'
    } = data

    let filename = name || 'profile'
    let mimeType = file.type

    if (file.name) {
      const fallBack = mimeTypes.lookup(file.name) || 'application/octet-stream'

      mimeType = file.type ? file.type : fallBack
      filename = file.name
    }

    const q = encodeURI(
      `name=${filename}&type=${mimeType}&category=${category}`
    )

    try {
      const { upload_url: fileUrl, db_url: dbUrl } = await this.$axios.$get(
        `/urls?${q}`
      )

      await this.$axios.$put(fileUrl, file, {
        headers: {
          'Content-Type': mimeType,
          'Content-Disposition': `attachment;filename="${filename}"`
        },
        transformRequest: [
          (data, headers) => {
            delete headers.Authorization
            delete headers.common.Authorization

            return data
          }
        ]
      })

      return dbUrl
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async getFileUrl({ commit }, url) {
    try {
      const baseUrl = this.$axios.defaults.baseURL.replace('/api/v1', '')

      const { data } = await this.$axios.get(`${baseUrl}${url}`)

      return data.url
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  }
}
