export default {
	queueList(state) {
		return state.queueList
	},
	loading(state) {
		return state.loading
	},
	actionLoading(state) {
		return state.actionLoading
	},
	responseStatus(state) {
		return state.isSuccess
	},
	customerGroups(state) {
		return state.customerGroups
	},
	channels(state) {
		return state.channels
	},
	message(state) {
		return state.message
	},
	queueFilters(state) {
		return state.queueFilters
	},
	agentFilter(state) {
		return state.agentFilter
	},
	selectedAgents(state) {
		return state.selectedAgents
	}
}