import Moment from 'moment'

export default {
  async getAll({ commit }) {
    try {
      const { tickets } = await this.$axios.$get('/tickets')

      const allTicketsCount = tickets.length
      const resolvedTicketsCount = tickets.filter(v => v.TicketStatus === 'R')
        .length
      const closedTicketsCount = tickets.filter(v => v.TicketStatus === 'C')
        .length
      const myTicketsCount = tickets.filter(v =>
        ['O', 'H', 'P'].includes(v.TicketStatus)
      ).length

      commit('SET_MY_TICKETS_TOTAL', myTicketsCount)
      commit('SET_CLOSED_TICKETS_TOTAL', closedTicketsCount)
      commit('SET_RESOLVED_TICKETS_TOTAL', resolvedTicketsCount)
      commit('SET_ALL_TICKETS_TOTAL', allTicketsCount)

      commit('SET_ALL', tickets || [])
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async getByStatus({ commit }, payload) {
    try {
      const { status: st, channelId: cid, prevDays: pd } = payload

      const q = []

      if (st.length) {
        st.forEach(v => {
          q.push(`status=${v}`)
        })
      }

      if (cid) {
        q.push(`channelId=${cid}`)
      }

      if (pd) {
        q.push(`prevDays=${pd}`)
      }

      commit('SET_LOADING', true)

      const { tickets } = await this.$axios.$get(`/tickets?${q.join('&')}`)

      commit('SET_TICKETS', {
        tickets: tickets && tickets.length ? tickets : []
      })

      return tickets && tickets.length ? tickets : []
    } catch (e) {
      commit('API_ERROR', e, { root: true })

      return []
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async updateStatus({ commit }, payload) {
    try {
      const { id, params } = payload
      commit('SET_UPDATE_STATUS_LOADING', true)

      const { reason, ...p } = params
      let body = { ...p }

      // add reason to payload if value is Int
      if (typeof reason === 'number' && Math.floor(reason) === reason) {
        body = { ...body, reason }
      }

      await this.$axios.$put(`/tickets/${id}`, body)
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_UPDATE_STATUS_LOADING', false)
    }
  },

  async getReasons({ commit }) {
    try {
      commit('SET_REASONS_LOADING', true)

      const { reasons } = await this.$axios.$get('/reasons')

      commit('SET_REASONS', reasons)
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_REASONS_LOADING', false)
    }
  },

  async getTicketHistory({ commit }, payload) {
    const { id } = payload

    try {
      commit('SET_GET_HISTORY_LOADING', true)

      const { history } = await this.$axios.$get(`/tickets/${id}/history`)

      commit('SET_TICKET_HISTORY', history)
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_GET_HISTORY_LOADING', false)
    }
  },

  async getAllHistory({ commit, state }, payload) {
    const {
      From: startDate,
      To: endDate,
      q,
      AgentId,
      ChannelId,
      TicketStatus
    } = state.subFilters
    const { cursor, pageDir: dir, limit } = state.pagination
    const from = Moment(startDate || '')
      .startOf('day')
      .utcOffset(0, true)
      .format()
    const to = Moment(endDate || '')
      .endOf('day')
      .utcOffset(0, true)
      .format()
    try {
      const { history } = await this.$axios.$get(
        `/tickets/all?fromDate=${from}&toDate=${to}&cursor=${cursor}&dir=${dir}&limit=${limit}&q=${q ||
          ''}&agentId=${AgentId || ''}&channel=${ChannelId ||
          ''}&status=${TicketStatus || 'N,O,P,H,R,C'}`
      )

      const { prevCursor, nextCursor, list } = history
      commit('SET_ALL_HISTORY', list || [])
      commit('SET_PAGINATION', {
        cursorOption: [prevCursor || '', nextCursor || '']
      })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async updateAssignee({ commit }, payload) {
    const { ticketId, agentId, updatedBy } = payload
    try {
      await this.$axios.put(`/tickets/${ticketId}/actions/update-assignee`, {
        ticketId,
        agentId,
        updatedBy
      })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async updateTicketStatus({ commit }, payload) {
    const { ticketId, status, updatedBy } = payload
    try {
      await this.$axios.$put(`/tickets/${ticketId}`, { status, updatedBy })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  setStatsFilter({ commit }, filter) {
    commit('SET_STATS_FILTER', filter)
  },

  async getStats({ state, commit }) {
    const { from, to, agentId } = state.statsFilter

    try {
      const { data } = await this.$axios.get(
        `/reports/statistics?fromDate=${from}&toDate=${to}&agentId=${agentId}`
      )
      commit('SET_STATS', data)
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async setUnreadLogs({ commit }, payload) {
    const { ticketId } = payload
    try {
      await this.$axios.$put(`/tickets/${ticketId}/chat-logs/actions/read`)
      commit('REMOVE_UNREAD_LOGS', payload)
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  }
}
