export default () => ({
  agents: [],
  agentList: [],
  agentCount: 0,
  loading: true,
  settings: {},
  agentFilters: {
    q: '',
    status: ''
  }
})
