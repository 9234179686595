import moment from 'moment'
import $message from 'ant-design-vue/lib/message'
import channels from '~/json/channels.json'
import status from '~/json/status.json'
import { browserNotify, playSound } from '~/utils/common.js'

export default {
  SOCKET_CONNECT(state) {
    console.info(`Socket is connected`)
  },

  SOCKET_DISCONNECT(state, status) {
    console.warn(`Socket ${status}`)
  },

  SOCKET_DISCONNECTING(state, status) {
    console.info('Socket is disconnecting')
  },

  SOCKET_CHAT(state, chat) {
    console.info('ws - chat >>>>>', chat)

    //

    const nuxt = window.$nuxt
    const m = {
      ChatId: Number(chat.chat_id),
      TicketId: Number(chat.ticket_id),
      StatusId: Number(chat.status_id),
      ConnErrCode: Number(chat.conn_error_code),
      ConnMsgId: chat.conn_msg_id,
      ClientMsgId: chat.client_msg_id,
      UMID: chat.umid,
      refId: chat.ref_id
    }

    const selectedTicket = this.getters['tickets/selectedTicket']

    if (
      m.TicketId === selectedTicket.TicketId &&
      nuxt.$route.name === 'conversations-my-conversations'
    ) {
      if (selectedTicket.TicketStatus === 'H') {
        // this ticket will become OPEN
        nuxt.$router.push({
          path: '/conversations/my-conversations',
          query: { tid: selectedTicket.TicketId, ts: new Date().getTime() }
        })
      } else {
        this.commit('tickets/UPDATE_CHAT_LOG_STATUS', m, { root: true })
      }
    }

    state.chat.push({
      type: 'chat',
      payload: chat
    })
  },

  SOCKET_ADMIN_CHAT(state, chat) {
    console.info('ws - admin chat >>>>>', chat)

    const nuxt = window.$nuxt
    const m = {
      ChatId: Number(chat.chat_id),
      TicketId: Number(chat.ticket_id),
      StatusId: Number(chat.status_id),
      ConnErrCode: Number(chat.conn_error_code),
      ConnMsgId: chat.conn_msg_id,
      ClientMsgId: chat.client_msg_id,
      UMID: chat.umid,
      refId: chat.ref_id
    }

    const selectedTicket = this.getters['tickets/selectedTicket']

    if (
      m.TicketId === selectedTicket.TicketId &&
      nuxt.$route.name === 'admin-conversations'
    ) {
      this.commit('tickets/UPDATE_CHAT_LOG_STATUS', m, { root: true })

      if (selectedTicket.TicketStatus === 'H') {
        // this ticket will become OPEN
        const payload = {
          TicketId: m.TicketId,
          TicketStatus: 'H',
          UpdatedAt: moment.utc(new Date()).toISOString()
        }
        this.commit('tickets/UPDATE_ALL_HISTORY', payload, { root: true })
      } else {
        this.commit('tickets/UPDATE_CHAT_LOG_STATUS', m, { root: true })
      }
    }

    state.adminChat.push({
      type: 'chat',
      payload: chat
    })
  },

  SOCKET_MESSAGE(state, message) {
    console.info('ws - message >>>>>', message)

    const nuxt = window.$nuxt
    const {
      ticket_id: t,
      channel: ChannelId,
      umid: UMID,
      content,
      chat_id: c,
      msisdn,
      content_type: ct,
      new_status: StatusTo,
      old_status: StatusFrom,
      client_id: ClientId,
      display_name: ClientName,
      ReplyToUMID
    } = message

    const m = {
      TicketId: Number(t),
      ChannelId,
      UMID,
      MSISDN: msisdn,
      ChatId: Number(c),
      UpdatedAt: moment.utc(new Date()).toISOString(),
      CreatedAt: moment.utc(new Date()).toISOString(),
      Content: content,
      ContentType: ct || 'text',
      StatusTo,
      StatusFrom,
      TicketStatus: StatusTo,
      OldStatus: StatusFrom,
      ClientId: ClientId || 44444411444,
      ClientName,
      DisplayName: ClientName,
      Inbound: true,
      ReplyToUMID
    }

    const selectedTicket = this.getters['tickets/selectedTicket']
    const tickets = this.getters['tickets/tickets']

    const { name } = channels.find(v => v.code === m.ChannelId)

    // update ticket content and content type
    this.commit('tickets/UPDATE_TICKET_CONTENT', m, { root: true })

    browserNotify('NEW MESSAGE', {
      body: `[${m.DisplayName || m.MSISDN || ''}]`,
      icon: require(`~/assets/images/channels/png/${name.toLowerCase()}.png`)
    })

    playSound()

    // this.commit(
    //   'tickets/MANAGE_TOTALS',
    //   {
    //     oldStatus: StatusFrom,
    //     newStatus: StatusTo,
    //     type: 'message'
    //   },
    //   { root: true }
    // )

    // if open /onhold ticket is selected, agent should recieve messages from the customer
    if (
      (m.StatusFrom === 'O' && m.StatusTo === 'O') ||
      (m.StatusFrom === 'H' && m.StatusTo === 'H')
    ) {
      if (
        Object.keys(selectedTicket).length &&
        selectedTicket.TicketId === m.TicketId &&
        nuxt.$route.name === 'conversations-my-conversations'
      ) {
        this.commit('tickets/ADD_CHAT_LOG', m, { root: true })
      }
    }

    // if resolved ticket will be moved to open
    if (m.StatusFrom === 'R' && m.StatusTo === 'O') {
      // if agent is in the resolved page and currently selecting the ticketID
      if (
        Object.keys(selectedTicket).length &&
        selectedTicket.TicketId === m.TicketId
      ) {
        $message.warning('Conversation has been moved to OPEN')
        nuxt.$router.push({
          path: '/conversations/my-conversations',
          query: { tid: m.TicketId, ts: new Date().getTime() }
        })
      }

      // add new ticket (possibly an open ticket)
      if (tickets.some(v => v.TicketId !== m.TicketId)) {
        this.commit('tickets/ADD_TICKET', m, { root: true })
      }
    }

    // if pending ticket/ onhold will be moved to open
    if ((m.StatusFrom === 'P' || m.StatusFrom === 'H') && m.StatusTo === 'O') {
      if (nuxt.$route.name === 'conversations-my-conversations') {
        if (
          Object.keys(selectedTicket).length &&
          m.TicketId === selectedTicket.TicketId
        ) {
          $message.warning('Conversation has been moved to OPEN')
          nuxt.$router.push({
            path: '/conversations/my-conversations',
            query: { tid: m.TicketId, ts: new Date().getTime() }
          })
        } else if (tickets.length) {
          this.commit(
            'tickets/CHANGE_TICKET_STATUS',
            {
              ticket: m,
              newStatus: m.TicketStatus,
              oldStatus: m.StatusFrom
            },
            { root: true }
          )
        } else {
          nuxt.$router.push({
            path: '/conversations/my-conversations',
            query: { tid: m.TicketId, ts: new Date().getTime() }
          })
        }
      }
    }

    if (selectedTicket.TicketId !== m.TicketId) {
      // Update UnreadLogs in a ticket
      this.commit('tickets/UPDATE_UNREAD_LOGS', m, { root: true })
    } else {
      const p = { ticketId: m.TicketId }
      this.dispatch('tickets/setUnreadLogs', p)
    }

    state.message.push({
      type: 'message',
      payload: message
    })
  },

  SOCKET_ADMIN_MESSAGE(state, message) {
    console.info('ws - admin message >>>>>', message)

    const nuxt = window.$nuxt

    const {
      ticket_id: t,
      channel: ChannelId,
      umid: UMID,
      content,
      chat_id: c,
      msisdn,
      content_type: ct,
      new_status: StatusTo,
      old_status: StatusFrom,
      client_id: ClientId,
      display_name: ClientName,
      Inbound,
      ReplyToUMID
    } = message

    const m = {
      TicketId: Number(t),
      ChannelId,
      UMID,
      MSISDN: msisdn,
      ChatId: Number(c),
      UpdatedAt: moment.utc(new Date()).toISOString(),
      CreatedAt: moment.utc(new Date()).toISOString(),
      Content: content,
      ContentType: ct || 'text',
      StatusTo,
      StatusFrom,
      TicketStatus: StatusTo,
      OldStatus: StatusFrom,
      ClientId,
      ClientName,
      DisplayName: ClientName,
      Inbound,
      ReplyToUMID
    }

    if (Inbound) {
      playSound()
    }

    // Update history list
    if (StatusTo !== StatusFrom) {
      this.commit('tickets/UPDATE_ALL_HISTORY', m, {
        root: true
      })
    }

    const selectedTicket = this.getters['tickets/selectedTicket']

    if (
      Object.keys(selectedTicket).length &&
      selectedTicket.TicketId === m.TicketId &&
      m.Inbound &&
      nuxt.$route.name === 'admin-conversations'
    ) {
      this.commit('tickets/ADD_CHAT_LOG', m, { root: true })
    }
  },

  SOCKET_ADMIN_NEW_TICKET(state, ticket) {
    console.info('ws - admin new ticket >>>>>', ticket)

    const {
      channel,
      ticket_id: id,
      chat_id: chatId,
      content,
      client_id: clientId,
      new_status: newStatus,
      msisdn,
      display_name: displayName,
      agentId: assignTo
    } = ticket

    const m = {
      ChannelId: channel,
      TicketId: Number(id),
      ChatId: Number(chatId),
      Content: content,
      MSISDN: msisdn || '',
      ClientId: clientId,
      ClientName: displayName || '',
      TicketStatus: newStatus,
      AgentId: assignTo,
      UpdatedAt: moment.utc(new Date()).toISOString()
    }

    const tickets = this.getters['tickets/allHistory']
    const t = tickets.find(v => v.TicketId === m.TicketId)

    // If ticket doesn't exist then add it on top of list
    if (!t) {
      this.commit('tickets/ADD_ALL_HISTORY', m, { root: true })
    } else {
      this.commit('tickets/UPDATE_ALL_HISTORY', m, {
        root: true
      })
    }
  },

  SOCKET_TICKET(state, ticket) {
    console.info('ws - ticket >>>>>', ticket)

    const nuxt = window.$nuxt
    const {
      action,
      channel,
      ticket_id: id,
      chat_id: chatId,
      content_type: contentType,
      content,
      client_id: clientId,
      new_status: newStatus,
      old_status: oldStatus,
      msisdn,
      display_name: DisplayName
    } = ticket

    const m = {
      ChannelId: channel,
      TicketId: Number(id),
      ChatId: Number(chatId),
      ContentType: contentType,
      Content: content,
      MSISDN: msisdn,
      ClientId: clientId,
      DisplayName,
      TicketStatus: newStatus,
      OldStatus: oldStatus
    }

    const tickets = this.getters['tickets/tickets']
    const selected = tickets.find(v => v.TicketId === m.TicketId)

    // there are times that ticket is lacking of data, so fill in
    if (selected) {
      Object.keys(m).forEach(v => {
        if (!m[v] && selected[v]) {
          m[v] = selected[v]
        }
      })
    }

    const { name: channelId } = channels.find(v => v.code === m.ChannelId)

    // this.commit(
    //   'tickets/MANAGE_TOTALS',
    //   {
    //     oldStatus,
    //     newStatus,
    //     type: 'ticket',
    //     action
    //   },
    //   { root: true }
    // )

    const bn = (a = 'added') => {
      if (a === 'removed') {
        browserNotify('TICKET REMOVED', {
          body: `[${m.TicketId}] ticket has been removed.`,
          icon: require(`~/assets/images/channels/png/${channelId.toLowerCase()}.png`)
        })
      } else {
        browserNotify('TICKET ADDED', {
          body: `[${m.TicketId}] ticket has been added.`,
          icon: require(`~/assets/images/channels/png/${channelId.toLowerCase()}.png`)
        })
      }
    }

    switch (action) {
      case 'add': {
        if (
          nuxt.$route.name === 'conversations-my-conversations' &&
          ['O', 'P', 'H'].includes(m.TicketStatus)
        ) {
          if (tickets.length) {
            this.commit('tickets/ADD_TICKET', m, { root: true })
          } else {
            nuxt.$router.push({
              path: '/conversations/my-conversations',
              query: { tid: m.TicketId, ts: new Date().getTime() }
            })
          }
        }

        if (
          nuxt.$route.name === 'conversations-resolved' &&
          m.TicketStatus === 'R'
        ) {
          if (tickets.length) {
            this.commit('tickets/ADD_TICKET', m, { root: true })
          } else {
            nuxt.$router.push({
              path: '/conversations/resolved',
              query: { tid: m.TicketId, ts: new Date().getTime() }
            })
          }
        }

        if (
          nuxt.$route.name === 'conversations-closed' &&
          m.TicketStatus === 'C'
        ) {
          if (tickets.length) {
            this.commit('tickets/ADD_TICKET', m, { root: true })
          } else {
            nuxt.$router.push({
              path: '/conversations/closed',
              query: { tid: m.TicketId, ts: new Date().getTime() }
            })
          }
        }
        bn()
        break
      }

      case 'remove': {
        this.commit('tickets/REMOVE_TICKET', m, { root: true })
        bn('removed')
        break
      }
    }

    state.ticket.push({
      type: 'ticket',
      payload: ticket
    })
  },

  SOCKET_ADMIN_TICKET(state, ticket) {
    console.info('ws - admin ticket agent changed >>>>>', ticket)
    const payload = {
      TicketId: Number(ticket.ticket_id),
      AgentId: ticket.assign_to,
      TicketStatus: ticket.new_status,
      UpdatedAt: moment.utc(new Date()).toISOString()
    }
    this.commit('tickets/UPDATE_ALL_HISTORY', payload, { root: true })
  },

  SOCKET_STATUS(state, ticket) {
    console.info('ws - ticket status changed >>>>>', ticket)

    const nuxt = window.$nuxt
    const {
      channel: ChannelId,
      ticket_id: TicketId,
      chat_id: ChatId,
      content_type: ContentType,
      content: Content,
      client_id: ClientId,
      new_status: TicketStatus,
      msisdn: MSISDN,
      display_name: DisplayName,
      old_status: OldStatus
    } = ticket

    const t = {
      TicketId: Number(TicketId),
      ChannelId,
      Content,
      ChatId: Number(ChatId),
      ContentType,
      ClientId: Number(ClientId),
      TicketStatus,
      OldStatus,
      MSISDN,
      DisplayName
    }

    const selectedTicket = this.getters['tickets/selectedTicket']
    const tickets = this.getters['tickets/tickets']

    // there are times that ticket is lacking of data, so fill in if ticket is available
    const selected = tickets.find(v => v.TicketId === t.TicketId)

    if (selected) {
      Object.keys(t).forEach(v => {
        if (!t[v] && selected[v]) {
          t[v] = selected[v]
        }
      })
    }

    const { name: statusName } = status.find(v => v.code === t.TicketStatus)
    const { name: channelId } = channels.find(v => v.code === t.ChannelId)

    // this.commit(
    //   'tickets/MANAGE_TOTALS',
    //   {
    //     oldStatus: OldStatus,
    //     newStatus: t.TicketStatus,
    //     type: 'status'
    //   },
    //   { root: true }
    // )

    const bn = (a = 'changed') => {
      if (a === 'removed') {
        browserNotify('TICKET REMOVED', {
          body: `[${t.TicketId}] ticket has been removed.`,
          icon: require(`~/assets/images/channels/png/${channelId.toLowerCase()}.png`)
        })
      } else if (a === 'added') {
        browserNotify('TICKET ADDED', {
          body: `[${t.TicketId}] ticket has been added.`,
          icon: require(`~/assets/images/channels/png/${channelId.toLowerCase()}.png`)
        })
      } else {
        browserNotify('TICKET STATUS CHANGED', {
          body: `[${t.TicketId}] Ticket status has been changed.`,
          icon: require(`~/assets/images/channels/png/${channelId.toLowerCase()}.png`)
        })
      }
    }

    switch (nuxt.$route.name) {
      case 'conversations-my-conversations': {
        const mys = ['O', 'H', 'P']

        if (mys.includes(OldStatus)) {
          if (mys.includes(t.TicketStatus)) {
            if (selectedTicket.TicketId === t.TicketId) {
              bn()

              $message.warning(
                `Conversation has been mark as ${statusName.toUpperCase()}.`
              )
              nuxt.$router.push({
                path: '/conversations/my-conversations',
                query: { tid: t.TicketId, ts: new Date().getTime() }
              })
            } else {
              this.commit(
                'tickets/CHANGE_TICKET_STATUS',
                {
                  ticket: t,
                  newStatus: t.TicketStatus,
                  oldStatus: OldStatus
                },
                { root: true }
              )

              bn()
            }
          } else {
            this.commit('tickets/REMOVE_TICKET', t, { root: true })

            bn('removed')
          }
        } else if (mys.includes(t.TicketStatus)) {
          if (tickets.length) {
            this.commit('tickets/ADD_TICKET', t, { root: true })
          } else {
            nuxt.$router.push({
              path: '/conversations/my-conversations',
              query: { tid: t.TicketId, ts: new Date().getTime() }
            })
          }
          bn('added')
        }

        break
      }

      case 'conversations-resolved': {
        if (t.TicketStatus === 'R') {
          if (tickets.length) {
            this.commit('tickets/ADD_TICKET', t, { root: true })
          } else {
            nuxt.$router.push({
              path: '/conversations/resolved',
              query: { tid: t.TicketId, ts: new Date().getTime() }
            })
          }
        }

        if (OldStatus === 'R') {
          if (selectedTicket.TicketId === t.TicketId) {
            $message.warning(
              `Conversation has been mark as ${statusName.toUpperCase()}.`
            )

            let path = 'closed'

            if (['O', 'H', 'P'].includes(t.TicketStatus)) {
              path = 'my-conversations'
            }

            bn()

            nuxt.$router.push({
              path: `/conversations/${path}`,
              query: { tid: t.TicketId, ts: new Date().getTime() }
            })
          } else {
            this.commit('tickets/REMOVE_TICKET', t, { root: true })
            bn('removed')
          }
        }
        break
      }

      case 'conversations-closed': {
        if (t.TicketStatus === 'C') {
          if (tickets.length) {
            this.commit('tickets/ADD_TICKET', t, { root: true })
          } else {
            nuxt.$router.push({
              path: '/conversations/closed',
              query: { tid: t.TicketId, ts: new Date().getTime() }
            })
          }
          bn()
        }

        if (OldStatus === 'C') {
          if (selectedTicket.TicketId === t.TicketId) {
            $message.warning(
              `Conversation has been mark as ${statusName.toUpperCase()}.`
            )

            let path = 'resolved'

            if (['O', 'H', 'P'].includes(t.TicketStatus)) {
              path = 'my-conversations'
            }

            bn()

            nuxt.$router.push({
              path: `/conversations/${path}`,
              query: { tid: t.TicketId, ts: new Date().getTime() }
            })
          } else {
            this.commit('tickets/REMOVE_TICKET', t, { root: true })
            bn('removed')
          }
        }
        break
      }

      default:
        bn()
    }
  },

  SOCKET_ADMIN_TICKET_STATUS(state, ticket) {
    console.info('ws - admin ticket status changed >>>>>', ticket)
    const payload = {
      TicketId: Number(ticket.ticket_id),
      TicketStatus: ticket.new_status,
      UpdatedAt: moment.utc(new Date()).toISOString(),
      ClientId: ticket.client_id
    }

    console.info('ADMIN TICKET STATUS: ', payload)
    this.commit('tickets/UPDATE_ALL_HISTORY', payload, { root: true })
  },

  SOCKET_KICK(state, reason) {
    console.info('ws - kick >>>>>', reason)

    $message.error(reason.message || 'Please login to continue.')

    this.$auth.logout()
  },

  SOCKET_UPDATE_REPORTS(state, reports) {
    console.info('ws - update reports >>>>>', reports)

    const nuxt = window.$nuxt

    if (nuxt.$route.name === 'index') {
      this.dispatch('tickets/getStats')
    }
  }
}
