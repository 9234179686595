var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"layout":'horizontal',"footer":null,"closable":true,"maskClosable":true,"title":"Remove 2FA authenticator app?","width":"20%","centered":""},on:{"cancel":_vm.handleResetModal}},[_c('a-form',{ref:"authremoveappmodal",attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":_vm.handlePasswordSubmit}},[_c('a-form-item',[_c('template',{slot:"label"},[_vm._v("Please input your password")]),_vm._v(" "),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password', {
            rules: [
              { required: true, message: 'Please enter a valid password' }
            ]
          }
        ]),expression:"[\n          'password', {\n            rules: [\n              { required: true, message: 'Please enter a valid password' }\n            ]\n          }\n        ]"}],attrs:{"disabled":_vm.updating,"autoFocus":""}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],2),_vm._v(" "),_c('a-form-item',[_c('a-button',{staticClass:"mr-3",attrs:{"loading":_vm.updating,"html-type":"submit","type":"danger"}},[_vm._v("\n        Yes, remove 2FA\n      ")]),_vm._v(" "),_c('a-button',{attrs:{"disabled":_vm.updating},on:{"click":_vm.handleResetModal}},[_vm._v("\n        Cancel\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }