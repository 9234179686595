import Vue from 'vue'
import VueRx from 'vue-rx'
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd'

Vue.use(VueRx)
Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperPreview: false
  }
})
