<template>
  <a-modal
    :visible="!!Object.keys(data || {}).length"
    :title="type === 'EDIT_MODE' ? 'Edit profile' : 'Agent information'"
    :mask="!isShowChangePasswordModal && !avatarImageSrc"
    @cancel="handleResetModal"
    :width="500"
  >
    <div class="flex space-x-8">
      <div class="flex-grow">
        <a-form
          ref="profileModal"
          :form="form"
          layout="vertical"
          hide-required-mark
        >
          <h1 class="text-sm text-gray-800 mb-3 font-semibold">
            Name
          </h1>
          <div class="flex space-x-2">
            <div class="w-1/2 mr-2">
              <a-form-item>
                <template slot="label">
                  <p class="text-xs text-gray-500 mb-0">First name</p>
                </template>
                <a-input
                  v-if="type === 'EDIT_MODE'"
                  v-decorator="[
                    'first_name',
                    {
                      initialValue: data.first_name || data.FirstName,
                      rules: [
                        {
                          max: 50,
                          message: 'First name must be less than 50 character'
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Z\s]*$/, 'g'),
                          message: 'First name should only contain letters'
                        }
                      ]
                    }
                  ]"
                  :disabled="updating"
                  placeholder="John"
                />
                <span v-else class="text-lg">{{
                  data.first_name || data.FirstName || '—'
                }}</span>
              </a-form-item>
            </div>
            <div class="w-1/2">
              <a-form-item>
                <template slot="label">
                  <p class="text-xs text-gray-500 mb-0">Last name</p>
                </template>
                <a-input
                  v-if="type === 'EDIT_MODE'"
                  v-decorator="[
                    'last_name',
                    {
                      initialValue: data.last_name || data.LastName,
                      rules: [
                        {
                          max: 50,
                          message: 'Last name must be less than 50 character'
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Z\s]*$/, 'g'),
                          message: 'Last name should only contain letters'
                        }
                      ]
                    }
                  ]"
                  :disabled="updating"
                  placeholder="Smith"
                />
                <span v-else class="text-lg">{{
                  data.last_name || data.LastName || '—'
                }}</span>
              </a-form-item>
            </div>
          </div>

          <h1 class="text-sm text-gray-800 mb-3 font-semibold">
            Account information
          </h1>
          <a-form-item>
            <template slot="label">
              <p class="text-xs text-gray-500 mb-0">Display name</p>
            </template>
            <a-input
              v-if="type === 'EDIT_MODE'"
              v-decorator="[
                'display_name',
                {
                  initialValue: data.display_name || data.DisplayName,
                  rules: [
                    {
                      max: 15,
                      message: 'Display name must be less than 15 character'
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9_]*$/, 'g'),
                      message:
                        'Display name should only contain alphanumeric characters and underscores'
                    }
                  ]
                }
              ]"
              :disabled="updating"
              placeholder="Please enter display name"
            />
            <span v-else class="text-lg">{{
              data.display_name || data.DisplayName || '—'
            }}</span>
          </a-form-item>

          <a-form-item>
            <template slot="label">
              <p class="text-xs text-gray-500 mb-1">Email</p>
            </template>
            <div class="break-all text-lg">
              {{ data.email || data.Email || '—' }}
            </div>
          </a-form-item>

          <div v-if="type === 'VIEW_MODE'" class="flex">
            <a-form-item class="w-1/2">
              <template slot="label">
                <p class="text-xs text-gray-500 mb-1">Date joined</p>
              </template>
              <div
                v-if="data.created_at || data.CreatedAt"
                class="break-all text-lg"
              >
                {{
                  moment(data.created_at || data.CreatedAt).format(
                    'MMM D, YYYY'
                  )
                }}
              </div>
              <div v-else>—</div>
            </a-form-item>
            <a-form-item class="w-1/2">
              <template slot="label">
                <p class="text-xs text-gray-500 mb-1">Last seen online</p>
              </template>
              <div
                v-if="
                  data.last_logged_at ||
                    data.LastLoginAt ||
                    data.last_logged_out ||
                    data.LastLogoutAt
                "
                class="break-all text-lg"
              >
                <div v-if="data.is_logged_in || data.IsLoggedIn">
                  {{
                    moment(data.last_logged_at || data.LastLoginAt).format(
                      'MMM D, YYYY'
                    )
                  }}
                </div>
                <div v-else>
                  {{
                    moment(data.last_logged_out || data.LastLogoutAt).format(
                      'MMM D, YYYY'
                    )
                  }}
                </div>
                <div
                  v-if="data.is_logged_in || data.IsLoggedIn"
                  class="text-sm text-green-500"
                >
                  Online now
                </div>
              </div>
              <div v-else>—</div>
            </a-form-item>
          </div>

          <a-form-item>
            <template slot="label">
              <p class="text-xs text-gray-500 mb-1">Role</p>
            </template>
            <div v-if="type === 'VIEW_MODE'" class="w-40">
              <a-select
                v-decorator="[
                  'role',
                  {
                    initialValue: data.role || data.Role || 'U',
                    rules: [{ required: true, message: 'Please select a role' }]
                  }
                ]"
                placeholder="Please select a role"
              >
                <a-select-option
                  v-for="roleKey in Object.keys(agentRoles)"
                  :value="roleKey"
                  :key="roleKey"
                >
                  {{ agentRoles[roleKey] }}
                </a-select-option>
              </a-select>
            </div>
            <span v-else class="text-lg">
              {{ agentRoles[data.role || data.Role] || '—' }}
            </span>
          </a-form-item>
        </a-form>
      </div>
      <div class="relative ml-5">
        <h1 class="text-sm text-gray-800 mb-3 font-semibold">
          Avatar
        </h1>
        <div
          @click="onClickAvatar"
          :disabled="updating"
          class="w-40 h-40 rounded-full bg-black bg-center bg-cover border border-gray-500 relative cursor-pointer"
          role="img"
          aria-label="avatar"
        >
          <div v-if="type === 'EDIT_MODE'">
            <input
              ref="avatarInput"
              @input="handleEditAvatar"
              type="file"
              class="hidden"
              accept="image/png, image/jpg"
            />
            <image-async
              :url="data.profile_url || data.ProfileUrl || ''"
              class="rounded-full"
            />
            <span
              role="none"
              class="absolute bottom-0 left-0 m-1 p-2 border border-gray-500 text-xs space-x-1 flex items-center bg-white rounded-sm leading-none"
            >
              <i class="icon-edit-2" />
              <span>Edit</span>
            </span>
          </div>
          <div v-else>
            <image-async
              :url="data.profile_url || data.ProfileUrl || ''"
              class="rounded-full"
            />
          </div>
        </div>
        <a-popconfirm
          @confirm="handleRemoveAvatar"
          title="Are you sure remove the avatar?"
          ok-text="Remove"
          cancel-text="Cancel"
          placement="bottom"
        >
          <button
            v-if="data.profile_url"
            class="mt-3 text-red-500 text-xs mx-auto block hover:underline"
          >
            Remove photo
          </button>
        </a-popconfirm>
      </div>
    </div>

    <template slot="footer">
      <div v-if="type === 'EDIT_MODE'" class="flex justify-between">
        <a-button
          @click="isShowChangePasswordModal = true"
          type="link"
          class="text-red-500"
        >
          <a-icon type="lock" />
          Change password
        </a-button>
        <a-popconfirm
          @confirm="handleUpdateProfile"
          :disabled="!isFormChanged"
          title="Are you sure update changes?"
          ok-text="Update"
          cancel-text="Cancel"
        >
          <a-button
            :disabled="!isFormChanged"
            :loading="updating"
            type="primary"
          >
            Update profile
          </a-button>
        </a-popconfirm>
      </div>
      <div v-else class="flex justify-between">
        <div class="flex items-center">
          <a-popconfirm
            @confirm="$emit('handle-remove-user', data)"
            title="Are you sure remove this user"
            ok-text="Remove user"
            cancel-text="Cancel"
          >
            <a-button :disabled="updating" type="link" class="text-red-500">
              <a-icon type="close" />
              Remove user
            </a-button>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-popconfirm
            @confirm="$emit('handle-send-reset-password', data)"
            title="Are you sure send reset password email?"
            ok-text="Send email"
            cancel-text="Cancel"
          >
            <a-button type="link">
              <a-icon type="mail" />
              Reset password
            </a-button>
          </a-popconfirm>
        </div>
        <a-button
          @click="handleUpdateAccessAgent"
          :disabled="!isFormChanged"
          type="primary"
        >
          Update access
        </a-button>
      </div>
    </template>

    <change-password-modal
      v-if="type === 'EDIT_MODE'"
      :visible="isShowChangePasswordModal"
      :user="data"
      @handle-toggle-modal="isShowChangePasswordModal = false"
      @handle-update-password="handleUpdatePassword"
      :updating="updating"
    />

    <crop-image-modal
      :image-src="avatarImageSrc"
      @handle-close-modal="handleCloseAvatarModal"
      @handle-update-image-src="handleUpdateAvatar"
      :updating="updating"
    />
  </a-modal>
</template>

<script>
import moment from 'moment'
import { mapMutations } from 'vuex'

import agentRoles from '@/json/agentRoles.json'

import ChangePasswordModal from '@/components/agent/change-password-modal.vue'
import CropImageModal from '@/components/agent/crop-image-modal.vue'
import ImageAsync from '@/components/common/image-async.vue'

import picMixin from '@/mixins/pic.js'

import { blobToBase64 } from '@/utils/common'

export default {
  name: 'ProfileModal',

  components: {
    ChangePasswordModal,
    CropImageModal,
    ImageAsync
  },

  mixins: [picMixin],

  props: {
    type: {
      type: String,
      default: 'EDIT_MODE',
      validator: value => {
        return ['EDIT_MODE', 'VIEW_MODE'].includes((value || '').toUpperCase())
      }
    },
    data: {
      type: Object,
      required: true
    },

    updating: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      moment,
      agentRoles,
      isFormChanged: false,
      isShowChangePasswordModal: false,
      avatarImageSrc: null
    }
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, {
      onValuesChange: (_, values) => {
        this.isFormChanged = true
      }
    })
  },

  methods: {
    ...mapMutations({
      updateProfile: 'user/SET_PROFILE'
    }),

    handleResetModal() {
      this.$emit('handle-update-data', {})

      if (this.isFormChanged) {
        this.isFormChanged = false
        this.form.resetFields()
      }
    },

    handleUpdateAccessAgent() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          const { AgentId: agentId, agent_id: agentId2 } = this.data
          const payload = {
            agentId: agentId2 || agentId,
            ...values
          }

          await this.$emit('handle-update-user-role', payload)
          await this.$emit('handle-get-agents')

          // Close modal and reset selected data
          this.handleResetModal()
        }
      })
    },

    handleEditAvatar() {
      if (!this.$refs.avatarInput) {
        return 0
      }
      const imgInput = this.$refs.avatarInput
      const file = imgInput.files

      if (file && file.length) {
        this.avatarImageSrc = file[0]
      }
    },

    handleCloseAvatarModal() {
      if (!this.$refs.avatarInput) {
        return 0
      }

      const imgInput = this.$refs.avatarInput
      imgInput.value = null
      this.avatarImageSrc = null
    },

    handleRemoveAvatar() {
      this.$emit('handle-remove-avatar')
    },

    handleUpdateProfile() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const payload = {
            user_id: this.data.user_id,
            ...values
          }
          this.$emit('handle-update-profile', payload, this.handleResetModal)
        }
      })
    },

    async handleUpdateAvatar(blob, callback = () => {}) {
      try {
        const base64Image = await blobToBase64(blob)

        this.$emit('handle-update-avatar', blob, base64Image, callback)
      } catch (err) {
        this.$bugsnag.notify(err)
      } finally {
        this.loading = false
      }
    },

    handleUpdatePassword(payload, callback) {
      this.$emit('handle-update-password', payload, callback)
    },

    // Trigger image input click when wrapper is clicked
    onClickAvatar() {
      if (!this.$refs.avatarInput) {
        return 0
      }

      this.$refs.avatarInput.click()
    }
  }
}
</script>
