export default {
  async getConfig({ commit, state }, payload = {}) {
    const { tenantId } = payload

    try {
      commit('SET_LOADING', true)
      const { config } = await this.$axios.$get(`/tenants/${tenantId}/settings`)

      commit('SET_CONFIG_DATA', config)
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async saveConfig({ commit, state }, payload = {}) {
    const { tenantId, params } = payload

    try {
      commit('SET_SAVE_LOADING', true)

      await this.$axios.$put(`/tenants/${tenantId}/settings`, params)
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_SAVE_LOADING', false)
    }
  }
}
