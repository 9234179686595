<template>
  <header class="top-header shadow flex">
    <div class="flex">
      <div class="p-3 relative flex">
        <a-tooltip :mouse-enter-delay="1" placement="bottom">
          <template slot="title">
            <span>{{ mute ? 'Muted' : 'Sound on' }}</span>
          </template>
          <a
            :class="[
              { 'bg-gray-200 text-gray-700': mute },
              { 'bg-blue-200 text-blue-700': !mute }
            ]"
            @click.prevent="handleToggleSound"
            class="h-full rounded p-2 inline-block"
          >
            <transition name="fade-up" mode="out-in">
              <img
                :key="mute"
                :src="
                  require(`~/assets/images/apps/${
                    mute ? 'sound-mute' : 'sound'
                  }.svg`)
                "
                alt="mute"
              />
            </transition>
          </a>
        </a-tooltip>
      </div>
      <div class="bg-white flex rounded-bl-lg">
        <div
          v-if="this.$auth.user.role.toLowerCase() !== 'admin'"
          :class="`${isAvailable ? 'available' : 'unavailable'}`"
        >
          <a-popover
            :visible="visible"
            @click="openPopover"
            class="status cursor-pointer"
            placement="bottomLeft"
            destroy-tooltip-on-hide
          >
            <template slot="content"
              ><div class="st-popover">
                <small class="text-sm text-gray">Status</small>
                <div class="mt-3">
                  <a-checkbox
                    :checked="checked"
                    @change="onChangeStatus"
                    class="text-sm"
                    >Allow incoming messages</a-checkbox
                  >
                </div>
                <div class="flex mt-5">
                  <div class="flex-auto">
                    <a-button
                      :disabled="checked === isAvailable"
                      :loading="loading"
                      @click="changeAvailabilty"
                      type="primary"
                      class="mr-12"
                      >Set status</a-button
                    >
                  </div>
                  <div class="flex-auto text-right">
                    <a-button @click="closePopover" type="default"
                      >Cancel</a-button
                    >
                  </div>
                </div>
              </div>
            </template>

            <div class="flex av px-4 py-3 h-full items-center">
              <div class="flex items-center mr-3">
                <img
                  :src="
                    require(`~/assets/images/apps/${
                      isAvailable ? 'status-available' : 'status-unavailable'
                    }.svg`)
                  "
                  alt="status"
                />
              </div>
              <div class="relative">
                <span class="text-gray st st-1 block">STATUS</span>
                <span class="text-sm st st-2 font-semibold block mt-1">{{
                  isAvailable ? 'Available' : 'Unavailable'
                }}</span>
              </div>
              <div class="ml-4 flex items-center">
                <i class="arrow down" />
              </div>
            </div>
          </a-popover>
        </div>
        <div class="flex-initial text-right px-3 profile rounded-bl-lg profile">
          <a-dropdown placement="bottomLeft">
            <a @click.prevent class="ant-dropdown-link block" href="#">
              <div class="flex items-center">
                <div
                  class="bg-black h-8 w-8 rounded-full mr-1 bg-center bg-cover border border-gray-500 relative "
                >
                  <image-async
                    :url="profile.profile_url || ''"
                    class="rounded-full"
                  />
                </div>
                <span class="name text-black inline-block ml-1 mr-3">
                  {{ getName() }}
                </span>
                <i class="arrow down ml-1" />
              </div>
            </a>
            <a-menu slot="overlay">
              <!-- <a-menu-item key="1">
                <a href="#" @click.prevent
                  ><a-icon type="setting" class="align-middle mr-1" />
                  Settings</a
                >
              </a-menu-item>
              <a-menu-divider /> -->
              <a-menu-item key="3">
                <a @click.prevent="viewProfile">
                  <a-icon type="user" class="align-middle mr-1" />
                  View Profile
                </a>
              </a-menu-item>

              <a-menu-item key="4">
                <a @click.prevent="viewSecurity">
                  <a-icon type="safety-certificate" class="align-middle mr-1" />
                  Security
                </a>
              </a-menu-item>
              <a-menu-item key="5">
                <a @click.prevent="logout()">
                  <a-icon type="logout" class="align-middle mr-1" /> Logout
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
    </div>
    <profile-modal
      :data="agentData"
      @handle-update-data="agentData = {}"
      @handle-update-avatar="updateAvatar"
      @handle-update-profile="updateProfile"
      @handle-update-password="updatePassword"
      @handle-remove-avatar="removeAvatar"
      :updating="updating"
    />
    <security-modal
      :visible="isShowSecurityModal"
      :two-fa="twoFaData"
      :updating="updating"
      @handle-security-modal="handleShowSecurityModal"
    />
  </header>
</template>
<script>
// import Search from '~/components/conversations/search.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'

import profileModal from '@/components/agent/profile-modal.vue'
import securityModal from '@/components/user/security-modal.vue'
import ImageAsync from '@/components/common/image-async.vue'
import picMixin from '~/mixins/pic.js'

export default {
  name: 'Header',
  components: {
    profileModal,
    securityModal,
    ImageAsync
  },

  mixins: [picMixin],

  data() {
    return {
      mute: false,
      visible: false,
      checked: false,
      agentData: {},
      twoFaData: [],
      updating: false,
      isShowSecurityModal: false
    }
  },

  computed: {
    ...mapGetters({
      isAvailable: 'user/isAvailable',
      loading: 'user/loading',
      profile: 'user/profile',
      agentFilters: 'agents/agentFilters',
      getTwoFa: 'user/getTwoFa'
    })
  },

  watch: {
    profile(val) {
      // Do not update agentData if no agent is selected
      if (!Object.keys(this.agentData || {}).length) {
        return 0
      }

      this.agentData = val
    },
    getTwoFa(val) {
      if (!Object.keys(this.twoFaData || {}).length) {
        return 0
      }

      this.twoFaData = val
    }
  },

  beforeCreate() {
    if (this.$auth.user.role !== 'admin') {
      this.$store.commit('user/SET_AVAILABILITY', this.$auth.user.isAvailable, {
        root: true
      })
    }
  },

  created() {
    const token = this.$auth.strategy.refreshToken.get().replace('Bearer ', '')

    // Set profile values
    this.updateUserProfile(this.$auth.user)
    this.fetchProfile()

    if (token && !this.$socket.connected) {
      this.$socket.client.io.opts.transports = ['websocket']
      this.$socket.client.io.opts.query = { auth_token: token }
      this.$socket.client.io.opts.extraHeaders = {
        Authorization: `Bearer ${token}`
      }

      // on reconnection, reset the transports option, as the Websocket
      // connection may have failed (caused by proxy, firewall, browser, ...)
      this.$socket.client.on('reconnect_attempt', () => {
        this.$socket.client.io.opts.transports = ['websocket']
      })

      this.$socket.client.on('message', data => console.info(data))

      this.$socket.client.on('disconnect', reason => {
        console.info('Disconnection reason', reason)

        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server, you need to reconnect manually
          this.$socket.client.open()
          console.info('Socket has been manually connected.')
        }
        // else the socket will automatically try to reconnect
      })

      this.$socket.client.open()
    }

    if (this.$auth.user.role !== 'admin') {
      this.checked = this.isAvailable
    }

    const muteLocalStorage = localStorage.getItem('mute')
    // check if sound is muted
    if (muteLocalStorage) {
      this.mute = parseInt(muteLocalStorage) === 0
    }
  },

  methods: {
    ...mapActions({
      updateTwoFA: 'user/updateTwoFA',
      setAvatar: 'user/updateAvatar',
      setProfile: 'user/updateProfile',
      setPassword: 'user/updatePassword',
      getProfile: 'user/getProfile',
      uploadFile: 'common/uploadFile'
    }),
    ...mapMutations({
      updateUserProfile: 'user/SET_PROFILE'
    }),

    viewProfile() {
      if (!Object.keys(this.profile || {}).length) {
        return 0
      }
      this.agentData = this.profile
    },

    viewSecurity() {
      if (!Object.keys(this.getTwoFa || {}).length) {
        return 0
      }
      this.twoFaData = this.getTwoFa
      this.handleShowSecurityModal(true)
    },

    handleShowSecurityModal(bool) {
      this.isShowSecurityModal = bool
    },

    handleToggleSound() {
      this.mute = !this.mute
      localStorage.setItem('mute', this.mute ? 0 : 1)
    },

    isDisplaySearch() {
      const pn = [
        '/conversations/my-conversations',
        '/conversations/closed',
        '/conversations/resolved'
      ]
      return pn.includes(this.$route.path)
    },

    async logout() {
      this.$socket.client.disconnect()

      await this.$auth.logout()

      this.$store.commit('tickets/CLEAR', true, { root: true })
    },

    async changeAvailabilty() {
      try {
        await this.$store.dispatch(
          'user/updateAvailabilityStatus',
          { isAvailable: this.checked },
          {
            root: true
          }
        )

        this.checked = this.isAvailable
        this.visible = false

        let msg =
          "Status has been changed to unavailable. You won't recieve any new messages"

        if (this.checked) {
          msg =
            'Status has been changed to available. You will recieve now new messages'
        }

        this.$message.success(msg)

        await this.$store.dispatch('agents/getAllAgents', this.agentFilters, {
          root: true
        })
      } catch (e) {}
    },

    closePopover() {
      this.visible = false

      this.checked = this.isAvailable
    },

    onChangeStatus(e) {
      this.checked = e.target.checked
    },

    openPopover() {
      this.visible = true
    },

    getName() {
      if (!this.profile) {
        return ''
      }

      const {
        first_name: firstName,
        last_name: lastName,
        display_name: displayName,
        email
      } = this.profile

      if (firstName && lastName) {
        return `${firstName} ${lastName}`
      }

      if (firstName && !lastName) {
        return `${firstName}`
      }

      if (!firstName && lastName) {
        return `${lastName}`
      }

      if (displayName) {
        return displayName
      }

      return email.substring(0, email.indexOf('@'))
    },

    async fetchProfile() {
      await this.getProfile()
    },

    async updatePassword(payload, callback = () => {}) {
      const { old_password: oldPassword, new_password: newPassword } = payload
      try {
        this.updating = true
        await this.setPassword({ oldPassword, newPassword })

        callback()

        await this.logout()

        this.$message.success('Password has been changed successfully.')
        this.$message.success('Please login again to continue.')
      } catch (e) {
        if (
          e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.match(/Password/i)
        ) {
          this.$message.error('Old Password is not valid!')
        } else {
          this.$message.error('Unable to update password. Please try again.')
        }
      } finally {
        this.updating = false
      }
    },

    async updateProfile(payload, callback = () => {}) {
      const {
        last_name: lastName,
        first_name: firstName,
        display_name: displayName
      } = payload
      try {
        this.updating = true
        await this.setProfile({ lastName, firstName, displayName })

        // get updated profile
        await this.getProfile()

        callback()

        this.$message.success('Profile has been updated successfully.')
      } catch (e) {
        this.$message.error('Unable to update profile. Please try again.')
      } finally {
        this.updating = false
      }
    },

    async updateAvatar(blob, base64, callback = () => {}) {
      const { user_id: id } = this.profile
      this.updating = true

      // make sure image has been loaded to avoid corruption
      if (blob && blob.size) {
        // convert to kb
        const sizeKb = blob.size / 1000

        if (sizeKb < 2) {
          this.$message.error('Image has not been loaded yet or corrupted.')
          this.updating = false
          return
        }
      }

      try {
        // temporarily set with base64 format
        this.updateUserProfile({ profile_url: base64 })

        // get upload url
        const fileUrl = await this.uploadFile({
          file: blob,
          category: 'profiles',
          name: `profile_${id}`
        })

        // save url to profile
        await this.setAvatar({ avatarUrl: fileUrl })

        // get updated profile
        await this.getProfile()

        callback()

        this.$message.success('Profile avatar has been updated successfully.')
      } catch (e) {
        this.$message.error(
          'Unable to update profile avatar. Please try again.'
        )
      } finally {
        this.updating = false
      }
    },

    async removeAvatar() {
      try {
        this.updating = true
        // save url to profile
        await this.setAvatar({ avatarUrl: '' })

        // get updated profile
        await this.getProfile()

        this.$message.success('Profile avatar has been removed successfully.')
      } catch (e) {
        this.$message.error(
          'Unable to remove profile avatar. Please try again.'
        )
      } finally {
        this.updating = false
      }
    }
  }
}
</script>
<style lang="scss">
  .top-header {
    @apply rounded-b-lg;
    background-color: #2b354c;
    position: relative;

    .profile {
      background-color: #e2e8f0;
    }

    .av {
      width: 100%;
      line-height: 1;
    }

    .status {
      .st {
      }

      .st-1 {
        font-size: 7px;
      }

      .st-2 {
        font-size: 12px;
      }
    }

    .fade-up-enter-active {
      display: block;
      transition: 0.2s opacity 0s ease, 0.2s transform 0s ease;
    }

    .fade-up-leave-active {
      display: block;
      transition: 0.2s opacity 0s cubic-bezier(1, 0.5, 0.8, 1),
        0.2s transform 0s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .fade-up-enter {
      opacity: 0;
      transform: translateY(5px);
    }

    .fade-up-leave-to {
      opacity: 0;
      transform: translateY(-5px);
    }
  }

  .st-popover {
    width: 230px;
  }
</style>
