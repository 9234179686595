export default {
  methods: {
    getProfilePic(s) {
      // if (!s.DisplayName) return 'customer-z'

      let p = 'z'

      const name = s.DisplayName && s.DisplayName.trim()

      if (name && name.length) {
        if (name[0].match(/[a-z0-9]/i)) {
          p = name[0]
        }
      }

      if (s.MSISDN) {
        p = s.MSISDN.toString().slice(-1)
      }

      return `customer-${p.toLowerCase()}`
    },

    getAgentPic(c) {
      let p = 'agent-man'
      let name = ''

      if (c) {
        name = c.DisplayName || c.Email || c.display_name || c.first_name || ''
      }

      if (name.length > 7) {
        p = 'agent-lady'
      }

      return p
    }
  }
}
