<template>
  <div class="code-input">
    <div class="code-input__form">
      <input
        v-for="i in length"
        :ref="`otp-code${i}`"
        :key="i"
        :value="getDisplayValue(i)"
        :placeholder="placeholder"
        @select="selectInput"
        @keyup="removeValue($event, i)"
        @keypress="updateValue($event, i)"
        @keyup.enter="handleEnterKeyPress"
        readonly
        class="code-input__field"
        type="text"
        autofocus
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CodeInput',

    props: {
      length: {
        type: Number,
        default: 6,
      },

      placeholder: {
        type: String,
        default: '—',
      }
    },

    data() {
      return {
        codeArray: [],
        value: ''
      }
    },

    created() {
      this.codeArray = new Array(this.length).fill(-1)
    },

    methods: {
      updateValue(e, index) {
        const val = e.key
        if (val.match(/^[a-zA-Z0-9]$/)) {
          this.codeArray = this.codeArray.map((v, i) => (i === index - 1 ? val : v))
          const code = !this.codeArray.includes(-1)
            ? this.codeArray.join('')
            : null
          this.$emit('input', code)

          // Next input focus
          if (index < this.length) {
            this.$refs[`otp-code${index + 1}`][0].focus()
          }
        } else {
          e.preventDefault()
        }
      },

      removeValue(e, index) {
        if (e.keyCode === 8 || e.keyCode === 46) {
          this.codeArray = this.codeArray.map((v, i) => (i === index - 1 ? -1 : v))
          this.$emit('input', '')

          // Prev input focus
          if (index > 1) {
            this.$refs[`otp-code${index - 1}`][0].focus()
          }
        }
      },

      getDisplayValue(i) {
        const val = this.codeArray[i - 1]
        return val !== -1 ? val : ''
      },

      // Remove highlight input text
      selectInput() {
        window.getSelection().removeAllRanges()
      },

      handleEnterKeyPress() {
        this.$emit('submit')
      }
    },
  }
</script>

<style lang="scss" scoped>
  .code-input {
    display: inline-flex;
    padding: 5px 2px;
    position: relative;
    border-radius: 3px;
    border: solid 1px #3490DC;
    background: white;

    &__form {
      display: inline-flex;
      position: relative;
    }

    &__field {
      font-size: 20px;
      margin: 0 5px;
      width: 20px;
      text-align: center;
      display: inline-block;
      color: black;
      background: none;
      line-height: 1;
      position: relative;

      &:focus {
        background: whitesmoke;
        color: transparent;
        text-shadow: 0 0 0 #3490DC;
        outline: none;
      }
    }
  }
</style>
