export default {
  all(state) {
    return state.all
  },

  tickets(state) {
    return state.tickets
  },

  // for sidebar data
  resolvedTicketsTotal(state) {
    return parseInt(state.resolvedTicketsTotal || 0, 10)
  },

  closedTicketsTotal(state) {
    return parseInt(state.closedTicketsTotal || 0, 10)
  },

  myTicketsTotal(state) {
    return parseInt(state.myTicketsTotal || 0, 10)
  },

  allTicketsTotal(state) {
    return parseInt(state.allTicketsTotal || 0, 10)
  },

  selectedTicket(state) {
    return state.selectedTicket
  },

  loading(state) {
    return state.loading
  },

  allLoading(state) {
    return state.allLoading
  },

  reasons(state) {
    return state.reasons
  },

  reasonsLoading(state) {
    return state.reasonsLoading
  },

  statusUpdateLoading(state) {
    return state.statusUpdateLoading
  },

  historyLoading(state) {
    return state.historyLoading
  },

  ticketHistory(state) {
    return state.ticketHistory
  },

  allHistory(state) {
    return state.allHistory
  },

  subFilters(state) {
    return state.subFilters
  },

  stats(state) {
    return state.stats
  },

  pagination(state) {
    return state.pagination
  }
}
