import message from 'ant-design-vue/lib/message'

export const mutations = {
  API_ERROR(state, error) {
    // bugsnag reporting
    this.$bugsnag.notify(error)
    console.error('API ERROR', error.response)

    if (
      !error ||
      !error.response ||
      error.response.status === 501 ||
      error.response.status === 500
    ) {
      return
    }

    switch(error.response.status) {
      case 401:
        this.$auth
          .logout()
          .then(() => {
            message.error('Please login to continue')
            this.commit('tickets/CLEAR', true, { root: true })
          })
          .catch(() => {})
          break
      case 422:
        if (error.response && error.response?.data?.detail.length > 0) {
          message.error(`${error.response?.data?.detail[0]?.field} ${error.response?.data?.detail[0]?.message}`) 
          break
        }
        message.error(`Validation Failed: ${error.response?.data?.detail[0]?.field}`)
        break
      default:
        break
    }
  }
}

export const actions = {}

export const getters = {}
