<template>
  <aside id="sidebar" class="sidebar">
    <div class="text-center mb-5 logo">
      <img src="~/assets/images/logo/8x8-logo.svg" alt="8x8" class="h-full" />
    </div>
    <a-menu
      :default-selected-keys="[1]"
      :default-open-keys="[2, 3]"
      :selected-keys="getSelectedKeys()"
      mode="inline"
    >
      <template v-for="item in getMenu()">
        <a-menu-item
          v-if="!item.children"
          :key="item.key"
          class="sidebar__link"
        >
          <nuxt-link :to="item.link">
            <span class="menu-title text-sm">
              <a-icon
                v-if="item.icon"
                :type="item.icon"
                class="align-middle"
              />{{ item.name }}
            </span>
          </nuxt-link>
        </a-menu-item>
        <a-sub-menu v-else :key="item.key" :disabled="item.disabled">
          <span slot="title"
            ><span class="align-middle menu-title text-sm text-gray">{{
              getName(item.name)
            }}</span>
          </span>
          <a-menu-item
            v-for="c in item.children"
            :key="c.key"
            class="sidebar__link"
          >
            <nuxt-link v-if="c.show" :to="c.link" class="block w-full">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <a-icon v-if="c.icon" :type="c.icon" class="mr-2" />
                  <span
                    v-else
                    :class="`${c.class} dot rounded-full inline-block mr-2`"
                  >
                  </span>
                  <span class="text-sm align-top inline-block">
                    {{ c.name }}
                  </span>
                </div>
                <span class="text-right text-sm font-semibold">
                  <!-- {{ getTotals(c.name) }} -->
                  &nbsp;
                </span>
              </div>
            </nuxt-link>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </aside>
</template>
<script>
import { mapGetters } from 'vuex'
import routes from '~/json/routes.json'

export default {
  name: 'Sidebar',

  data() {
    return {
      navigations: routes
    }
  },

  computed: {
    ...mapGetters({
      resolvedTicketsCount: 'tickets/resolvedTicketsTotal',
      closedTicketsCount: 'tickets/closedTicketsTotal',
      myTicketsCount: 'tickets/myTicketsTotal',
      allTicketsCount: 'tickets/allTicketsTotal'
    })
  },

  methods: {
    getSelectedKeys() {
      const k = this.getMenu().reduce((a, b) => {
        if (b.link && this.$route.path === b.link) {
          a.push(b.key)
        }

        if (b.children) {
          const c = b.children.find(v => v.link === this.$route.path)

          if (c) {
            a.push(c.key)
          }
        }

        return a
      }, [])

      return k
    },

    getMenu() {
      const { role, tenant_id: tenantId } = this.$auth.user
      // supervisor
      let menu = this.navigations

      if (role && role === 'admin') {
        menu = menu.filter(i => i.name !== 'Conversations')
      } else if (!role || role === 'user') {
        menu = menu.filter(i => i.name !== 'Admin' && i.name !== 'Dashboard')
      }
      // Before Reverse
      // const tenants = ['2F7FE2FA-04AA-E911-814A-02D85F55FCE7', 'CD4711EB-05C7-ED11-B942-060D6FF3E2F2']

      // Blacklists
      const tenants = []

      if (tenants.length && tenants.includes(tenantId.toUpperCase())) {
        menu.map((v) => {
          if ((v.name === 'Admin' || v.name === 'Supervisor') &&  v.children && v.children.length) {
            v.children.map((c) => {
              if (c.name === 'Advanced Queuing') {
                c.show = false
              }

              return c
            })
          }

          return v
        })
      }

      return menu
    },

    getName(name) {
      const { role } = this.$auth.user

      if (role === 'supervisor' && name === 'Admin') {
        return 'Supervisor'
      }

      return name
    },

    getTotals(c) {
      let len = 0
      switch (c) {
        case 'My Conversations': {
          len = this.myTicketsCount || 0
          break
        }

        case 'Resolved': {
          len = this.resolvedTicketsCount || 0
          break
        }

        case 'Closed': {
          len = this.closedTicketsCount || 0
          break
        }
        default:
          len = this.allTicketsCount || 0
      }

      return len
    }
  }
}
</script>
<style lang="scss">
.sidebar {
  .logo {
    width: 60px;
  }

  &__link {
    padding: 10px !important;
    text-decoration: none;
    display: flex;
    align-items: center;
    border-radius: 4px;

    &:after {
      display: none;
    }
  }

  a {
    color: rgba(0, 0, 0, 0.65);

    &:hover {
      color: #1890ff;
    }
  }

  .ant-menu-submenu-arrow {
    display: none;
  }

  .ant-menu-submenu-title {
    height: auto !important;
    padding: 0 !important;
    font-weight: 600;
  }

  .ant-menu-item {
    height: auto;
    line-height: 1 !important;
  }

  .ant-menu-item-selected {
    background-color: white !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

    a {
      color: #cb2233 !important;
    }
  }
}
</style>
