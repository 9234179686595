export default {
  loading(state) {
    return state.loading
  },

  uploading(state) {
    return state.uploading
  },

  addNoteLoading(state) {
    return state.addNoteLoading
  }
}
