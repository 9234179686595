export default {
  loading(state) {
    return state.loading
  },

  saveLoading(state) {
    return state.saveLoading
  },

  configData(state) {
    return state.configData
  }
}
