<template>
  <img :src="imageUrl" class="w-full h-full absolute inset-0" />
</template>

<script>
import { mapActions } from 'vuex'
import emptyLogo from '@/assets/images/profiles/empty_photo.jpeg'

export default {
  name: 'ImageAsync',

  props: {
    url: {
      type: String,
      default: '',
      required: true
    }
  },

  data() {
    return {
      imageUrl: emptyLogo
    }
  },

  watch: {
    url(val, oldVal) {
      if (val !== oldVal) {
        this.getImageUrl()
      }

      if (!val) {
        this.imageUrl = emptyLogo
      }
    }
  },

  created() {
    this.getImageUrl()
  },

  methods: {
    ...mapActions({
      getFileUrl: 'common/getFileUrl'
    }),

    async getImageUrl() {
      if (!this.url) return
      if (
        this.url.match(
          /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/
        )
      ) {
        this.imageUrl = this.url
      } else {
        const url = await this.getFileUrl(this.url)

        this.imageUrl = url
      }
    }
  }
}
</script>
