var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"width":350,"centered":"","title":"Change password"},on:{"cancel":_vm.handleResetModal}},[_c('div',{staticClass:"w-64 mx-auto"},[_c('a-form',{ref:"changepasswordmodal",attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('p',{staticClass:"text-xs text-gray-500 mb-0"},[_vm._v("Old password")])]),_vm._v(" "),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'old_password',
            {
              rules: [
                { required: true, message: 'Please input old password' }
              ]
            }
          ]),expression:"[\n            'old_password',\n            {\n              rules: [\n                { required: true, message: 'Please input old password' }\n              ]\n            }\n          ]"}],attrs:{"disabled":_vm.updating,"placeholder":"Please enter old password"}})],2),_vm._v(" "),_c('a-form-item',[_c('template',{slot:"label"},[_c('p',{staticClass:"text-xs text-gray-500 mb-0"},[_vm._v("New password")])]),_vm._v(" "),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'new_password',
            {
              rules: [
                { required: true, message: 'Please input new password' },
                { min: 8, message: 'Password must have at least 8 character' }
              ]
            }
          ]),expression:"[\n            'new_password',\n            {\n              rules: [\n                { required: true, message: 'Please input new password' },\n                { min: 8, message: 'Password must have at least 8 character' }\n              ]\n            }\n          ]"}],attrs:{"disabled":_vm.updating,"placeholder":"Please enter new password"}})],2)],1)],1),_vm._v(" "),_c('template',{slot:"footer"},[_c('div',{staticClass:"flex justify-end items-center"},[_c('a-button',{key:"back",attrs:{"disabled":_vm.updating},on:{"click":_vm.handleResetModal}},[_vm._v("\n        Back\n      ")]),_vm._v(" "),_c('a-button',{key:"submit",attrs:{"loading":_vm.updating,"type":"primary"},on:{"click":_vm.handleChangePassword}},[_vm._v("\n        Change password\n      ")])],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }