<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :closable="false"
    @cancel="handleResetModal"
    width="50%"
    centered
  >
    <div class="text-xl font-semibold">
      <span class="pt-10 mt-10">
        Set up your authentication app
      </span>
    </div>
    <p class="mb-5 mt-2 text-gray">
      We recommend using Google Authenticator, MS Authenticator or LastPass Authenticator.
    </p>
    <div class="google-verification-form clearfix">
      <div class="google-verification__qr-container float-right text-center p-3">
        <a-spin
          :spinning="qrLoading"
          :indicator="indicator"
          wrapper-class-name="h-full"
        >
          <div class="google-verification-form__image">
            <img
              :src="qrCode"
              class="google-verification-form__qr"
              alt="qr-code"
            >
            <a
              v-if="!qrLoading && !qrCode"
              @click="generateQrCode"
              class="google-verification-form__qr-refresh"
            >
              <a-icon type="sync" class="google-verification-form__qr-refresh-icon" />
            </a>
          </div>
        </a-spin>
      </div>
      <div>
        <p class="text-black mb-2">
          1. Scan the QR code on the right
        </p>
        <p class="text-sm text-grey mb-10">
          In your authentication app, either scan the QR code on the right or type in the code manually
        </p>
        <p class="text-black mb-2">
          2. Enter the code below
        </p>
        <p class="text-sm text-grey mb-10">
          Enter the verification code generated by your authentication app in the box below
        </p>

        <span class="text-grey mb-1 block mt-5">Authentication code</span>
        <code-input
          @submit="submit"
          v-model="otpCode"
          class="flex"
        />

        <div class="flex mt-5">
          <a-button
            :loading="updating"
            :disabled="!otpCode || updating"
            @click="submit"
            class="mr-3"
            type="primary"
          >
            Verify
          </a-button>
          <a-button
            :disabled="updating"
            @click="handleResetModal"
          >
            Cancel
          </a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
  import CodeInput from './code-input.vue'

  export default {
    name: 'AuthGoogleModal',

    components: {
      CodeInput,
    },

    props: {
      visible: {
        type: Boolean,
        default: false
      },
      generateQr: {
        type: Function,
        default: () => {},
      }
    },

    data() {
      return {
        qrCode: '',
        otpCode: '',
        qrLoading: false,
        updating: false,
        indicator: <a-icon type="loading" style="font-size: 24px" spin />
      }
    },

    created() {
      this.generateQrCode()
    },

    methods: {
      generateQrCode() {
        this.qrLoading = true
        this.$recaptchaLoaded()
          .then(() => this.$recaptcha('generate_2fa_otp'))
          .then(token => this.generateQr({ gToken: token }))
          .then((res) => {
            this.qrCode = res
          })
          .catch((err) => {
            this.qrCode = ''
            this.$message.error(err)
          })
          .finally(() => {
            this.qrLoading = false
          })
      },

      handleResetModal() {
        if (this.updating) {
          return 0
        }
        this.$emit('handle-auth-google-modal', false)
      },

      submit() {
        if (this.otpCode) {
          const payload = {
            type: 'AUTHENTICATOR_APP',
            token: this.otpCode,
          }
          this.$emit('submit', payload)
        }
      },

      cancel() {
        this.$emit('back')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .google-verification-form {
    word-break: break-word;

    &__image {
      padding: 10px;
      width: 200px;
      height: 200px;
      object-fit: contain;
      border: solid 1px whitesmoke;
      position: relative;
    }

    &__qr {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__qr-container {
      margin-left: 50px;
    }

    &__qr-refresh {
      width: 30px;
      height: 30px;
      color: white;
      background: #3490DC;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background 0.5s ease-in;

      &:hover {
        background: #BCDEFA;
      }
    }

    &__qr-refresh-icon {
      color: white;
      font-size: 23px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }
</style>
