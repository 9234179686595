export default {
	SET_LOADING(state, val) {
		state.loading = val
	},
	SET_ACTION_LOADING(state, val) {
		state.actionLoading = val
	},
	SET_QUEUE_LIST(state, queues) {
		state.queueList = queues
	},
	SET_RESPONSE_STATUS(state, status) {
		state.isSuccess = status
	},
	SET_RESPONSE_MESSAGE(state, message) {
		state.message = message
	},
	SET_CUSTOMER_GROUPS(state, groups) {
		state.customerGroups = groups
	},
	SET_CHANNEL_LISTS(state, channels) {
		state.channels = channels
	},
	SET_QUEUE_FILTERS(state, filters) {
		state.queueFilters = { ...state.queueFilters, ...filters }
	},
	SET_AGENT_FILTER(state, value) {
		state.agentFilter = { q: value }
	},
	SELECTED_AGENTS(state, value) {
		state.selectedAgents = value
	}
}
