import Vue from 'vue'
import { formatNumber, isValidNumber } from 'libphonenumber-js'

export function formatPhoneNumber(pn, format = 'International') {
  let phoneNumber = pn

  if (pn) {
    if (pn.charAt(0) !== '+') phoneNumber = `+${pn}`

    if (isValidNumber(phoneNumber)) {
      return formatNumber(phoneNumber, format)
    }

    return pn
  }

  return ''
}

const filters = {
  formatPhoneNumber
}
export default filters

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
