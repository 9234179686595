import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagVue from '@bugsnag/plugin-vue'

export default (ctx, inject) => {
  const { app, env } = ctx
  let userId = ''
  let tenantId = ''

  if (app && app.$auth && app.$auth.loggedIn && app.$auth.user) {
    userId = app.$auth.user.user_id
    tenantId = app.$auth.user.tenant_id
  }

  const bugsnagClient = Bugsnag.start({
    apiKey: env.NUXT_ENV_BUGSNAG_KEY,
    plugins: [new BugsnagVue()],
    enabledReleaseStages: ['production', 'development'],
    releaseStage: env.NODE_ENV,
    user: {
      id: userId || ''
    },

    metadata: {
      tenantId
    }
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)

  window.bugsnag = bugsnagClient
  inject('bugsnag', bugsnagClient)
}
