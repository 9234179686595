export default {
  SET_AGENTS(state, agents) {
    state.agents = agents
  },

  SET_AGENT_LIST(state, agents) {
    state.agentList = agents
  },

  SET_AGENT_COUNT(state, n) {
    state.agentCount = n
  },

  SET_LOADING(state, bool) {
    state.loading = bool
  },

  SET_SETTINGS(state, settings) {
    state.settings = settings
  },

  SET_AGENT_FILTERS(state, filter) {
    state.agentFilters = { ...state.agentFilters, ...filter }
  }
}
