import moment from 'moment'
import shortid from 'shortid'
import mimeTypes from 'mime-types'
import constants from '~/constants'

const { SHORT_ID_ALLOWED_CHARS } = constants

shortid.characters(SHORT_ID_ALLOWED_CHARS)

export default {
  async getChatlogs({ commit, state }, payload = {}) {
    const { TicketId } = payload

    try {
      commit('SET_LOADING', true)
      const { chats } = await this.$axios.$get(`/tickets/${TicketId}/chat-logs`)

      const chatlogs = chats && Object.keys(chats).length ? chats : {}

      commit('tickets/ATTACH_DATA', { chatlogs }, { root: true })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async addReply({ commit }, payload = {}) {
    const { content, id, ticketId, caption, profileUrl } = payload

    const type = payload.contentType || 'text'

    const refId = shortid.generate()

    const n = {
      UMID: refId,
      Inbound: false,
      StatusId: 0,
      Content: content,
      ContentType: type,
      ChatId: id,
      UpdatedAt: moment.utc(new Date()).toISOString(),
      CreatedAt: moment.utc(new Date()).toISOString(),
      ProfileUrl: profileUrl || ''
    }

    commit('tickets/ADD_CHAT_LOG', n, { root: true })

    try {
      commit('SET_LOADING', true)
      await this.$axios.$post(`/chats/${id}/reply`, {
        content,
        caption,
        type,
        ticketId,
        refId
      })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async getPrevChatlogs({ commit }, ticket = null) {
    let prevChatlogs = null
    const chatId = ticket ? ticket.TicketId : null

    // Open prev chat modal
    commit('tickets/ATTACH_DATA', { prevChatlogs: {} }, { root: true })

    if (chatId) {
      try {
        const { chats } = await this.$axios.$get(`/tickets/${chatId}/chat-logs`)
        prevChatlogs = chats
      } catch (e) {
        prevChatlogs = {}
        commit('API_ERROR', e, { root: true })
      }
    }

    commit('tickets/ATTACH_DATA', { prevChatlogs }, { root: true })
  },

  async uploadFile({ commit, dispatch }, data = {}) {
    const { file, payload } = data

    const fallBack = mimeTypes.lookup(file.name) || 'application/octet-stream'

    const mimeType = file.type ? file.type : fallBack

    const q = encodeURI(`name=${file.name}&type=${mimeType}`)

    try {
      commit('SET_UPLOAD_LOADING', true)
      const { upload_url: fileUrl, db_url: dbUrl } = await this.$axios.$get(
        `/urls?${q}`
      )

      await this.$axios.$put(fileUrl, file, {
        headers: {
          'Content-Type': mimeType,
          'Content-Disposition': `attachment;filename="${file.name}"`
        },
        transformRequest: [
          (data, headers) => {
            delete headers.Authorization
            delete headers.common.Authorization

            return data
          }
        ]
      })

      const p = payload

      p.content = dbUrl
      p.caption = file.name

      await dispatch('addReply', p)
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_UPLOAD_LOADING', false)
    }
  },

  async addChatNote({ commit }, payload) {
    const { id, remarks } = payload

    try {
      commit('SET_ADD_CHAT_NOTE_LOADING', true)

      commit('SET_LOADING', true)

      await this.$axios.$post(`/chats/${id}/notes`, {
        remarks
      })

      const n = {
        NoteType: 'C',
        UpdatedAt: moment.utc(new Date()).toISOString(),
        CreatedAt: moment.utc(new Date()).toISOString(),
        Remarks: remarks,
        ChatId: id
      }

      commit('tickets/ADD_CHAT_LOG', n, { root: true })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_ADD_CHAT_NOTE_LOADING', false)
      commit('SET_LOADING', false)
    }
  },

  async getChatLogsByUMID({ commit }, payload) {
    const { umid } = payload
    try {
      const data = await this.$axios.$get(`/chats/logs/${umid}`)
      return data
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  }
}
