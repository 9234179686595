export default {
  SET_LOADING(state, loading) {
    state.loading = loading
  },

  SET_ADD_CHAT_NOTE_LOADING(state, loading) {
    state.addNoteLoading = loading
  },

  SET_UPLOAD_LOADING(state, loading) {
    state.uploading = loading
  }
}
