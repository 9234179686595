import notification from 'ant-design-vue/lib/notification'

export function browserNotify(title, options) {
  const getNotif = () => {
    const notif = new Notification(title, {
      ...options,
      sound: '',
      renotify: false,
      silent: true
    })

    notif.onclick = () => {
      window.focus() // eslint-disable-line no-undef
      notif.close()
    }
  }

  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      return getNotif()
    } else if (Notification.permission !== 'denied') {
      if (typeof Notification.requestPermission !== 'undefined') {
        return Notification.requestPermission().then(permission => {
          // If the user accepts, let's create a notification
          if (permission === 'granted') {
            getNotif()
          } else {
            notification.info({
              message: title,
              description: options.body || ''
            })
          }
        })
      } 
    }
  } else {
    notification.info({
      message: title,
      description: options.body || ''
    })
  }
}

export function replaceBreakLine(text = '') {
  const br2nl = (str, replaceMode) => {
    const replaceStr = replaceMode ? '\n' : ''
    // Includes <br>, <BR>, <br />, </br>
    return str.replace(/<br\s*(\s+[^>]+)*[/]?>/gi, replaceStr) // disable-eslint-line
  }

  let t = text.trim()

  t = br2nl(t, true)

  t = t.replace(/&nbsp;/g, '')

  return t
}

export function transformToWAValidTemplate(content = '') {
  let c = content.trim()

  if (!c) return ''

  if (c.match(/<[^>]*>/g)) {
    c = c.replace(/<\/p>/g, '\n').replace(/<p>/g, '')

    // for bold element
    c = c.replace(/<strong>|<\/strong>/g, '*')
    c = c.replace(/<b>|<\/b>/g, '*')

    // for italic element
    c = c.replace(/<em>|<\/em>/g, '_')
    c = c.replace(/<i>|<\/i>/g, '_')

    // for strikethrough
    c = c.replace(/<s>|<\/s>/g, '~')
    c = c.replace(/<strike>|<\/strike>/g, '~')
  }

  return c
}

export function transformWATemplateToHTML(content = '') {
  let c = content.trim()

  if (!c) return ''

  c = c.replace(/\*(\S[^*]+\S)\*/g, '<strong>$1</strong>')
  c = c.replace(/_(\S[^_]+\S)_/g, '<em>$1</em>')
  c = c.replace(/~(\S[^~]+\S)~/g, '<s>$1</s>')

  return c
}

export function removeSquareBrackets(content = '') {
  const c = content.trim()

  if (!c) return ''

  return c.replace(/\[[^\]]+\]/i, '')
}

export function playSound() {
  const src = require('@/assets/sounds/filling-your-inbox.mp3')
  const mute = parseInt(localStorage.getItem('mute')) === 0

  if (src && !mute) {
    const audio = new Audio(src)
    const playPromise = audio.play()

    if (playPromise !== undefined) {
      playPromise
        .then(function() {
          // audio.pause()
        })
        .catch(function(error) {
          console.error(error)
          // ....
        })
    }
  }
}

export function blobToBase64(blob) {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export function serializeObject(obj) {
  return Object.entries(obj)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')
}
