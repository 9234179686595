import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7a8be1a2 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _79c11d65 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _42cd8d63 = () => interopDefault(import('../pages/conversations/index.vue' /* webpackChunkName: "pages/conversations/index" */))
const _cbeadcda = () => interopDefault(import('../pages/admin/advance-queuing.vue' /* webpackChunkName: "pages/admin/advance-queuing" */))
const _6797416e = () => interopDefault(import('../pages/admin/agents.vue' /* webpackChunkName: "pages/admin/agents" */))
const _c6fa7874 = () => interopDefault(import('../pages/admin/configuration.vue' /* webpackChunkName: "pages/admin/configuration" */))
const _8eac73c0 = () => interopDefault(import('../pages/admin/conversations.vue' /* webpackChunkName: "pages/admin/conversations" */))
const _a740d7a0 = () => interopDefault(import('../pages/admin/edit-configurations.vue' /* webpackChunkName: "pages/admin/edit-configurations" */))
const _0c676e3c = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _7252f22b = () => interopDefault(import('../pages/conversations/closed.vue' /* webpackChunkName: "pages/conversations/closed" */))
const _487a286e = () => interopDefault(import('../pages/conversations/my-conversations.vue' /* webpackChunkName: "pages/conversations/my-conversations" */))
const _129f8e12 = () => interopDefault(import('../pages/conversations/resolved.vue' /* webpackChunkName: "pages/conversations/resolved" */))
const _65636a69 = () => interopDefault(import('../pages/auth/reset-password/_token.vue' /* webpackChunkName: "pages/auth/reset-password/_token" */))
const _223e8a62 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
      path: "/admin",
      component: _7a8be1a2,
      name: "admin"
    }, {
      path: "/callback",
      component: _79c11d65,
      name: "callback"
    }, {
      path: "/conversations",
      component: _42cd8d63,
      name: "conversations"
    }, {
      path: "/admin/advance-queuing",
      component: _cbeadcda,
      name: "admin-advance-queuing"
    }, {
      path: "/admin/agents",
      component: _6797416e,
      name: "admin-agents"
    }, {
      path: "/admin/configuration",
      component: _c6fa7874,
      name: "admin-configuration"
    }, {
      path: "/admin/conversations",
      component: _8eac73c0,
      name: "admin-conversations"
    }, {
      path: "/admin/edit-configurations",
      component: _a740d7a0,
      name: "admin-edit-configurations"
    }, {
      path: "/auth/login",
      component: _0c676e3c,
      name: "auth-login"
    }, {
      path: "/conversations/closed",
      component: _7252f22b,
      name: "conversations-closed"
    }, {
      path: "/conversations/my-conversations",
      component: _487a286e,
      name: "conversations-my-conversations"
    }, {
      path: "/conversations/resolved",
      component: _129f8e12,
      name: "conversations-resolved"
    }, {
      path: "/auth/reset-password/:token?",
      component: _65636a69,
      name: "auth-reset-password-token"
    }, {
      path: "/",
      component: _223e8a62,
      name: "index"
    }],

  fallback: false
}

export function createRouter() {
  return new Router(routerOptions)
}
