export default {
  async getAgents({ commit }) {
    try {
      const { Agents } = await this.$axios.$get(`/users/agents`)

      // Filter out Admin agents so that they won't be assigned a ticket
      const AgentWithoutAdmin = Agents.filter(a => a.Role !== 'A')

      commit('SET_AGENTS', AgentWithoutAdmin || [])
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async addAgent({ commit }, payload) {
    const data = await this.$axios.$post(`/agents`, payload)

    if (Object.keys(data).includes('error')) {
      commit('API_ERROR', data.error, { root: true })
    }

    return data
  },

  async removeAgent({ commit }, { agentId }) {
    const data = await this.$axios.$delete(`/agents/${agentId}`)

    if (Object.keys(data).includes('error')) {
      commit('API_ERROR', data.error, { root: true })
    }

    return data
  },

  async updateAgentRole({ commit }, { agentId, role }) {
    const data = await this.$axios.$patch(`/agents/${agentId}`, { role })

    if (Object.keys(data).includes('error')) {
      commit('API_ERROR', data.error, { root: true })
    }

    return data
  },

  async getAllAgents({ commit, rootState }, payload) {
    commit('SET_LOADING', true)
    try {
      const queries = Object.entries(payload || {})
        .map(([key, val]) => `${key}=${val}`)
        .join('&')

      const { Agents, AgentCount } = await this.$axios.$get(
        `/agents?${queries}`
      )
      commit('SET_AGENT_LIST', Agents || [])
      commit('SET_AGENT_COUNT', AgentCount || 0)

      // Get current the max agents in tenant settings
      // eslint-disable-next-line camelcase
      const tenantId = rootState?.user?.profile?.tenant_id
      const { config } = await this.$axios.$get(`/tenants/${tenantId}/settings`)
      commit('SET_SETTINGS', {
        ...config,
        AvailableAgentsSlot: (config.MaxAgents || 0) - (AgentCount || 0)
      })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async resetAgentPassword({ commit }, payload) {
    try {
      const res = await this.$axios.$post('/auth/forgot-password', payload)
      return res
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      return e
    }
  }
}
