<template>
  <div class="main-layout">
    <div v-if="$nuxt.isOffline">You are offline</div>
    <a-layout :has-sider="$auth.loggedIn" :class="getCls()">
      <a-layout-sider
        v-if="$auth.loggedIn"
        breakpoint="lg"
        collapsed-width="0"
        theme="light"
        class="border-r border-solid border-gray-300 fixed left-0 overflow-auto h-screen z-20"
      >
        <Sidebar />
      </a-layout-sider>

      <a-layout-header v-if="$auth.loggedIn" class="header">
        <Header />
      </a-layout-header>
      <a-layout-content
        :class="
          `overflow-auto z-1 ${
            !$auth.loggedIn ? 'login-layout' : 'content-layout'
          }`
        "
        ><nuxt
      /></a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import Sidebar from '~/components/common/sidebar'
import Header from '~/components/common/top'

export default {
  components: {
    Sidebar,
    Header
  },

  methods: {
    getCls() {
      const pn = [
        '/conversations/my-conversations',
        '/conversations/closed',
        '/conversations/resolved'
      ]
      return pn.includes(this.$route.path) ? 'no-overflow' : ''
    }
  }
}
</script>

<style scoped>
.header {
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
}

.content-layout {
  margin-top: 50px;
  margin-left: 200px;
  overflow-x: auto !important;
}
</style>
