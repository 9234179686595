export default {
    async getAllQueues({ commit }, searchParams) {
      commit('SET_LOADING', true)
      try {
        let path = '/queues'
        if (searchParams) {
          const queryString = Object.keys(searchParams).map(key => `${key}=${searchParams[key]}`).join('&')
          path += `?${queryString}`
        }
        const { queues } = await this.$axios.$get(path)

        let list = queues

        if (!searchParams.queueName) {
          list = queues.concat([
            { QueueId: 0, Name: 'Default Queue', Status: true, QueueType: [], CreatedAt: '', Priority: 0 }
          ])
        }

        commit('SET_QUEUE_LIST', list || [])

      } catch (e) {
        commit('API_ERROR', e, { root: true })
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async createQueue({ commit }, payload) {
      commit('SET_ACTION_LOADING', true)
      try {
        await this.$axios.$post(`/tenants/queues`, payload)

      } catch (err) { 
        commit('API_ERROR', err, { root: true })
        throw err
      } finally {
        commit('SET_ACTION_LOADING', false)
      }
    },

    async updateQueue({ commit }, payload) {
      commit('SET_ACTION_LOADING', true)
      const { queueId } = payload
      try {
        await this.$axios.$put(`/queues/${queueId}`, payload)

      } catch (err) { 
        commit('API_ERROR', err, { root: true })
        throw err
      } finally {
        commit('SET_ACTION_LOADING', false)
      }
    },

    async updatePriority({ commit }, payload) {
      commit('SET_ACTION_LOADING', true)
      const { queueId } = payload
      try {
        await this.$axios.$put(`/queues/${queueId}/update-queue-prio`, payload)

      } catch (err) { 
        commit('API_ERROR', err, { root: true })
        throw err
      } finally {
        commit('SET_ACTION_LOADING', false)
      }
    },
    async getCustomerGroups({ commit, rootState }) {
      // commit('SET_LOADING', true)
      try {
        const tenantId = rootState?.user?.profile?.tenant_id
        const { groups } = await this.$axios.$get(`/tenants/${tenantId}/groups`)

        commit('SET_CUSTOMER_GROUPS', groups.items)
      } catch(err) {
        commit('API_ERROR', err, { root: true })
        throw err
      } finally {
        // commit('SET_LOADING', false)
      }
    },
    async getChannelLists({ commit, rootState }) {
      // commit('SET_LOADING', true)

      try {
        const tenantId = rootState?.user?.profile?.tenant_id
        const { channels } = await this.$axios.$get(`/tenants/${tenantId}/channels`)

        commit('SET_CHANNEL_LISTS', channels)
      } catch (err) {
        commit('API_ERROR', err, { root: true })
        throw err
      } finally {
        // commit('SET_LOADING', false)
      }
    },
    async updateStatus({ commit }, payload) {
      commit('SET_LOADING', true)
      const { queueId, status } = payload

      try {
        await this.$axios.$put(`/queues/${queueId}/status`, { status })

      } catch (err) {
        commit('API_ERROR', err, { root: true })
        throw err
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async deleteQueue({ commit }, id) {
      commit('SET_LOADING', true)

      try {
        await this.$axios.$delete(`/queues/${id}`)

      } catch (err) {
        commit('API_ERROR', err, { root: true })
        throw err
      } finally {
        commit('SET_LOADING', false)
      }
    }
}