<template>
  <a-modal
    :visible="visible"
    :layout="'horizontal'"
    :footer="null"
    :closable="true"
    :maskClosable="true"
    @cancel="handleResetModal"
    title='Remove 2FA authenticator app?'
    width="20%"
    centered
  >
    <a-form
      :form="form"
      @submit="handlePasswordSubmit"
      ref="authremoveappmodal"
      layout="vertical"
    >
      <a-form-item>
        <template slot="label">Please input your password</template>
        <a-input-password
          v-decorator="[
            'password', {
              rules: [
                { required: true, message: 'Please enter a valid password' }
              ]
            }
          ]"
          :disabled="updating"
          autoFocus
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-button
          :loading="updating"
          class="mr-3"
          html-type="submit"
          type="danger"
        >
          Yes, remove 2FA
        </a-button>
        <a-button
          :disabled="updating"
          @click="handleResetModal"
        >
          Cancel
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'AuthRemoveAppModal',

    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        updating: false
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this)
    },

    methods: {
      ...mapActions({
        removeTwoFa: 'user/removeTwoFa',
      }),

      handleResetModal() {
        if (this.updating) {
          return 0
        }
        this.form.resetFields()
        this.$emit('handle-auth-remove-modal', false)
      },

      handlePasswordSubmit(e) {
        e.preventDefault()
        this.updating = true
        this.form.validateFields((err, values) => {
          if (!err) {
            const payload = {
              type: 'app',
              password: values.password
            }
            this.$emit('remove-auth', payload)
          }
        })
        this.updating = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .align-right {
    float: right;
  }
</style>