<template>
  <a-modal
    :visible="visible"
    @cancel="handleResetModal"
    :width="350"
    centered
    title="Change password"
  >
    <div class="w-64 mx-auto">
      <a-form
        ref="changepasswordmodal"
        :form="form"
        layout="vertical"
        hide-required-mark
      >
        <a-form-item>
          <template slot="label">
            <p class="text-xs text-gray-500 mb-0">Old password</p>
          </template>
          <a-input-password
            v-decorator="[
              'old_password',
              {
                rules: [
                  { required: true, message: 'Please input old password' }
                ]
              }
            ]"
            :disabled="updating"
            placeholder="Please enter old password"
          />
        </a-form-item>
        <a-form-item>
          <template slot="label">
            <p class="text-xs text-gray-500 mb-0">New password</p>
          </template>
          <a-input-password
            v-decorator="[
              'new_password',
              {
                rules: [
                  { required: true, message: 'Please input new password' },
                  { min: 8, message: 'Password must have at least 8 character' }
                ]
              }
            ]"
            :disabled="updating"
            placeholder="Please enter new password"
          />
        </a-form-item>
      </a-form>
    </div>

    <template slot="footer">
      <div class="flex justify-end items-center">
        <a-button key="back" :disabled="updating" @click="handleResetModal">
          Back
        </a-button>
        <a-button
          key="submit"
          :loading="updating"
          @click="handleChangePassword"
          type="primary"
        >
          Change password
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'ChangePasswordModal',

  props: {
    visible: {
      type: Boolean,
      default: false
    },

    user: {
      type: Object,
      required: true
    },

    updating: {
      type: Boolean,
      default: false
    }
  },

  beforeCreate() {
    this.form = this.$form.createForm(this)
  },

  methods: {
    handleResetModal() {
      if (this.updating) {
        return 0
      }

      this.loading = false
      this.form.resetFields()
      this.$emit('handle-toggle-modal', false)
    },

    handleChangePassword() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const payload = {
            user_id: this.user.user_id,
            ...values
          }
          this.$emit('handle-update-password', payload, this.handleResetModal)
        }
      })
    }
  }
}
</script>
