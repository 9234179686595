export default {
  async updateAvailabilityStatus({ commit }, payload = {}) {
    const { isAvailable } = payload

    commit('SET_LOADING', true)

    try {
      await this.$axios.$put('/users/me/actions/update-availability', {
        isAvailable
      })

      commit('SET_AVAILABILITY', isAvailable)
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      commit('SET_AVAILABILITY', !isAvailable, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async updateAvatar({ commit }, payload = {}) {
    commit('SET_LOADING', true)

    try {
      await this.$axios.$put('/users/me/actions/update-avatar', {
        ...payload
      })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async updateProfile({ commit }, payload = {}) {
    commit('SET_LOADING', true)

    try {
      await this.$axios.$put('/users/me/actions/update-profile', {
        ...payload
      })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async updatePassword({ commit }, payload = {}) {
    try {
      await this.$axios.$put('/users/me/actions/update-password', {
        ...payload
      })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async getProfile({ commit }, payload = {}) {
    try {
      const data = await this.$axios.$get('/users/me', {
        ...payload
      })

      commit('SET_PROFILE', { ...data })

      return data
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    }
  },

  async updateTwoFA({ commit }) {
    commit('SET_LOADING', true)
    try {
      const data = await this.$axios.$get('auth/2fa')
      commit('SET_TWO_FA', data)
      return data
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async removeAllTwoFa({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const { data } = await this.$axios.$delete('auth/2fa/all', {
        ...payload,
      })
      return data
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async removeTwoFa({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const { data } = await this.$axios.$delete(`auth/2fa/${payload.type}`, { data: payload })
      return data
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async setDefaultTwoFa({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const { data } = await this.$axios.$put(`auth/2fa/${payload.type}`)
      return data
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async enableTwoFa({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const { data } = await this.$axios.$post('auth/2fa/verify', {
        ...payload,
      })
      if (data.verified) {
        return data
      }
      commit('API_ERROR', 'Invalid code', { root: true })
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async getTwoFaQrCode({ commit }, p) {
    commit('SET_LOADING', true)
    try {
      const payload = { type: 'AUTHENTICATOR_APP', ...p }
      const { data } = await this.$axios.$post('auth/2fa', {
        ...payload,
      })
      return data.qrUrl
    } catch (e) {
      commit('API_ERROR', e, { root: true })
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  }
}
