export default {
  SET_ALL(state, data) {
    state.all = data
  },

  SET_TICKETS(state, payload) {
    const { tickets } = payload

    state.tickets = tickets
  },

  SET_SELECTED_TICKET(state, selected) {
    state.selectedTicket = selected
  },

  ADD_TICKET(state, ticket) {
    const n = [ticket]
    state.tickets = n.concat(state.tickets)
  },

  UPDATE_TICKET_CONTENT(state, payload) {
    const index = state.tickets.findIndex(t => t.TicketId === payload.TicketId)

    if (index !== -1) {
      const { Content, ContentType } = payload

      state.tickets[index].Content = Content
      state.tickets[index].ContentType = ContentType
    }
  },

  REMOVE_TICKET(state, ticket) {
    state.tickets = state.tickets.filter(v => v.TicketId !== ticket.TicketId)

    if (state.selectedTicket.TicketId === ticket.TicketId) {
      state.selectedTicket = {}

      let path = 'closed'

      if (state.tickets.length) {
        const t = state.tickets[0]

        if (['H', 'O', 'P'].includes(ticket.OldStatus)) {
          path = 'my-conversations'
        }

        if (ticket.OldStatus === 'R') {
          path = 'resolved'
        }

        window.$nuxt.$router.push({
          path: `/conversations/${path}`,
          query: { ticketId: t.TicketId, ts: new Date().getTime() }
        })
      } else {
        if (['H', 'O', 'P'].includes(ticket.TicketStatus)) {
          path = 'my-conversations'
        }

        if (ticket.TicketStatus === 'R') {
          path = 'resolved'
        }

        window.$nuxt.$router.push({
          path: `/conversations/${path}`,
          query: { ts: new Date().getTime() }
        })
      }
    }
  },

  ATTACH_DATA(state, data) {
    state.selectedTicket = { ...state.selectedTicket, ...data }
  },

  ADD_CHAT_LOG(state, log) {
    if (!Object.keys(state.selectedTicket).length || !log.ChatId) return

    const { IsNew, ChatId } = log

    let f

    if (state.selectedTicket.chatlogs) {
      f = Object.keys(state.selectedTicket.chatlogs).find(
        v => Number(v) === Number(ChatId)
      )
    }

    if (IsNew || !f) {
      if (!state.selectedTicket.chatlogs) {
        state.selectedTicket.chatlogs = []
      }
      state.selectedTicket.chatlogs[ChatId] = [log]
    } else if (f && state.selectedTicket.chatlogs[f].length) {
      state.selectedTicket.chatlogs[f].push(log)
    }

    state.selectedTicket = { ...state.selectedTicket }
  },

  UPDATE_CHAT_LOG_STATUS(state, log) {
    if (!Object.keys(state.selectedTicket).length || !log.ChatId) return

    let f

    if (state.selectedTicket.chatlogs) {
      f = Object.keys(state.selectedTicket.chatlogs).find(
        v => Number(v) === Number(log.ChatId)
      )
    }

    if (f) {
      // UMID pretends to be refID
      const index = state.selectedTicket.chatlogs[f].findIndex(
        v => v.UMID && v.UMID === log.refId && !v.Inbound
      )

      if (index !== -1) {
        state.selectedTicket.chatlogs[f][index].UMID = log.UMID
        state.selectedTicket.chatlogs[f][index].StatusId = log.StatusId
      }
    } else {
      let rlog = null

      // find the chatlog having refId
      if (state.selectedTicket.chatlogs) {
        Object.keys(state.selectedTicket.chatlogs).forEach(v => {
          if (
            state.selectedTicket.chatlogs[v] &&
            state.selectedTicket.chatlogs[v].length
          ) {
            // expect umid is a short ID
            const findRefIdLog = state.selectedTicket.chatlogs[v].find(
              x => x.UMID && x.UMID === log.refId
            )

            if (findRefIdLog) {
              rlog = findRefIdLog
            }
          }
        })
      }

      if (rlog) {
        // make sure to remove this log from where the chat it belongs
        if (
          state.selectedTicket.chatlogs[rlog.ChatId] &&
          state.selectedTicket.chatlogs[rlog.ChatId].length
        ) {
          // expect UMID is the short ref id
          const plogs = state.selectedTicket.chatlogs[rlog.ChatId].filter(
            v => v.UMID && v.UMID !== log.refId
          )

          state.selectedTicket.chatlogs[rlog.ChatId] = plogs
        }

        // replace new chat id and umid
        rlog.ChatId = log.ChatId
        rlog.UMID = log.UMID
        rlog.StatusId = log.StatusId

        // add new chat log with new chatId
        state.selectedTicket.chatlogs[log.ChatId] = [rlog]
      }
    }

    state.selectedTicket = { ...state.selectedTicket }
  },

  CHANGE_TICKET_STATUS(state, { ticket, newStatus, oldStatus }) {
    if (newStatus !== oldStatus) {
      const index = state.tickets.findIndex(v => v.TicketId === ticket.TicketId)
      if (index !== -1) {
        state.tickets[index].TicketStatus = newStatus

        // if content changes, make a change to current ticket
        if (state.tickets[index].Content !== ticket.Content) {
          state.tickets[index].Content = ticket.Content
          state.tickets[index].ContentType = ticket.ContentType
        }
      }
    }
  },

  UPDATE_PROFILE(state, payload) {
    const { params } = payload

    state.selectedTicket.DisplayName = params.name || ''

    state.selectedTicket = { ...state.selectedTicket }

    const index = state.tickets.findIndex(
      v => v.TicketId === state.selectedTicket.TicketId
    )

    if (index !== -1) {
      state.tickets[index].DisplayName = state.selectedTicket.DisplayName
    }
  },

  MANAGE_TOTALS(state, { newStatus, oldStatus, type, action }) {
    let allTickets = parseInt(state.allTicketsTotal || 0, 10)

    let myTickets = parseInt(state.myTicketsTotal || 0, 10)

    let resolvedTickets = parseInt(state.resolvedTicketsTotal || 0, 10)

    let closedTickets = parseInt(state.closedTicketsTotal || 0, 10)

    switch (type) {
      case 'chat': {
        break
      }

      case 'message': {
        if (oldStatus === 'R' && ['H', 'P', 'O'].includes(newStatus)) {
          myTickets = myTickets + 1
          resolvedTickets = resolvedTickets - 1
        }

        break
      }

      case 'ticket': {
        if (action === 'add') {
          if (['H', 'P', 'O'].includes(newStatus)) {
            myTickets = myTickets + 1
          }

          if (newStatus === 'R') {
            resolvedTickets = resolvedTickets + 1
          }

          if (newStatus === 'C') {
            closedTickets = closedTickets + 1
          }

          allTickets = allTickets + 1
        }

        if (action === 'remove') {
          if (['H', 'P', 'O'].includes(oldStatus)) {
            myTickets = myTickets - 1
          }

          if (oldStatus === 'R') {
            resolvedTickets = resolvedTickets - 1
          }

          if (oldStatus === 'C') {
            closedTickets = closedTickets - 1
          }

          allTickets = allTickets - 1
        }

        break
      }

      case 'status': {
        if (['H', 'P', 'O'].includes(oldStatus) && newStatus === 'R') {
          resolvedTickets = resolvedTickets + 1
          myTickets = myTickets - 1
        }

        if (['H', 'P', 'O'].includes(oldStatus) && newStatus === 'C') {
          closedTickets = closedTickets + 1
          myTickets = myTickets - 1
        }

        if (['H', 'P', 'O'].includes(newStatus) && oldStatus === 'R') {
          resolvedTickets = resolvedTickets - 1
          myTickets = myTickets + 1
        }

        if (['H', 'P', 'O'].includes(newStatus) && oldStatus === 'C') {
          closedTickets = closedTickets - 1
          myTickets = myTickets + 1
        }

        if (oldStatus === 'R' && newStatus === 'C') {
          closedTickets = closedTickets + 1
          resolvedTickets = resolvedTickets - 1
        }

        if (oldStatus === 'C' && newStatus === 'R') {
          closedTickets = closedTickets - 1
          resolvedTickets = resolvedTickets + 1
        }
        break
      }
    }

    state.allTicketsTotal = allTickets < 1 ? 0 : allTickets
    state.myTicketsTotal = myTickets < 1 ? 0 : myTickets
    state.resolvedTicketsTotal = resolvedTickets < 1 ? 0 : resolvedTickets
    state.closedTicketsTotal = closedTickets < 1 ? 0 : closedTickets
  },

  SET_LOADING(state, loading) {
    state.loading = loading
  },

  SET_ALL_LOADING(state, loading) {
    state.loading = loading
  },

  SET_REASONS(state, payload) {
    state.reasons = payload
  },

  SET_REASONS_LOADING(state, loading) {
    state.reasonsLoading = loading
  },

  SET_GET_HISTORY_LOADING(state, loading) {
    state.historyLoading = loading
  },

  SET_TICKET_HISTORY(state, payload) {
    state.ticketHistory = payload
  },

  SET_UPDATE_STATUS_LOADING(state, loading) {
    state.reasonsLoading = loading
  },

  SET_MY_TICKETS_TOTAL(state, total) {
    state.myTicketsTotal = total
  },

  SET_RESOLVED_TICKETS_TOTAL(state, total) {
    state.resolvedTicketsTotal = total
  },

  SET_CLOSED_TICKETS_TOTAL(state, total) {
    state.closedTicketsTotal = total
  },

  SET_ALL_TICKETS_TOTAL(state, total) {
    state.allTicketsTotal = total
  },

  CLEAR(state, ls) {
    state.all = []
    state.tickets = []
    state.selectedTicket = {}
    state.reasons = []
    state.ticketHistory = []
    state.allHistory = []
    state.subFilters = []

    if (ls) {
      state.resolvedTicketsTotal = 0
      state.closedTicketsTotal = 0
      state.myTicketsTotal = 0
      state.allTicketsTotal = 0
    }
  },

  SET_ALL_HISTORY(state, payload) {
    state.allHistory = payload
  },

  UPDATE_ALL_HISTORY(state, ticket) {
    const th = state.allHistory.slice(0)
    state.allHistory = th.map(t => {
      return t.TicketId !== ticket.TicketId ? t : { ...t, ...ticket }
    })
  },

  ADD_ALL_HISTORY(state, ticket) {
    const th = state.allHistory.slice(0)
    th.unshift(ticket)
    state.allHistory = th
  },

  SET_SUBFILTERS(state, filters) {
    state.subFilters = { ...state.subFilters, ...filters }
  },

  SET_STATS(state, stats) {
    state.stats = stats
  },

  SET_STATS_FILTER(state, filter) {
    state.statsFilter = { ...state.statsFilter, ...filter }
  },

  UPDATE_UNREAD_LOGS(state, payload) {
    const index = state.tickets.findIndex(t => t.TicketId === payload.TicketId)

    if (index !== -1 && Object.keys(state.tickets[index]).length) {
      state.tickets[index].UnreadChatLogsCount =
        state.tickets[index].UnreadChatLogsCount + 1
    }
  },

  REMOVE_UNREAD_LOGS(state, payload) {
    const index = state.tickets.findIndex(t => t.TicketId === payload.ticketId)

    if (index !== -1 && Object.keys(state.tickets[index]).length) {
      state.tickets[index].UnreadChatLogsCount = 0
    }
  },

  SET_PAGINATION(state, pageInfo = { pageDir: 1, currentPage: 1 }) {
    state.pagination = { ...state.pagination, ...pageInfo }
  },

  RESET_PAGINATION(state) {
    state.pagination = {
      ...state.pagination,
      pageDir: 1,
      currentPage: 1,
      cursor: ''
    }
  }
}
