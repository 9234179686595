export default {
  agents(state) {
    // Sort agent by DisplayName, FirstName or Email
    const sortedAgent = ([...state.agents] || []).sort(
      (currAgent, nextAgent) => {
        const currAgentName = (
          currAgent.DisplayName ||
          currAgent.Email ||
          ''
        ).toLowerCase()
        const nextAgentName = (
          nextAgent.DisplayName ||
          nextAgent.Email ||
          ''
        ).toLowerCase()

        if (currAgentName < nextAgentName) return -1
        if (currAgentName > nextAgentName) return 1
        return 0
      }
    )
    return sortedAgent
  },

  agentList(state) {
    return state.agentList
  },

  agentCount(state) {
    return state.agentCount
  },

  agentLoading(state) {
    return state.loading
  },

  settings(state) {
    return state.settings
  },

  agentFilters(state) {
    return state.agentFilters
  }
}
