export default {
  socketChat(state) {
    return state.chat
  },

  socketAdminChat(state) {
    return state.adminChat
  },

  socketMessage(state) {
    return state.message
  },

  socketAdminMessage(state) {
    return state.adminMessage
  },

  socketTicket(state) {
    return state.ticket
  },

  socketAdminTicketStatus(state) {
    return state.adminTicketStatus
  },

  socketAdminNewTicket(state) {
    return state.adminNewTicket
  },

  socketAdminTicket(state) {
    return state.adminTicket
  }
}
