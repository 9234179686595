<template>
  <a-modal
    :visible="!!imageSrc"
    @cancel="handleCloseModal"
    :title="title"
    :width="350"
  >
    <clipper-basic
      ref="clipper"
      :src="croppedImage"
      :ratio="1"
      :min-width="40"
      :min-height="40"
      :init-width="100"
      :init-height="100"
    />
    <template slot="footer">
      <a-button
        key="upload"
        :loading="updating"
        :disabled="loading"
        @click="handleCropImage"
        class="w-full"
        type="primary"
      >
        Upload avatar
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { clipperBasic } from 'vuejs-clipper'
export default {
  name: 'CropImageModal',

  components: {
    clipperBasic
  },

  props: {
    title: {
      type: String,
      default: 'Crop image'
    },

    imageSrc: {
      type: File,
      default: null
    },

    updating: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      croppedImage: null,
      loading: false
    }
  },

  watch: {
    imageSrc(file) {
      if (file) {
        this.loading = true
        const reader = new FileReader()
        reader.onload = e => {
          this.croppedImage = e.target.result
          this.loading = false
        }
        reader.readAsDataURL(file)
      }
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit('handle-close-modal')
      this.croppedImage = null
      this.loading = false
    },

    handleCropImage() {
      if (!this.$refs.clipper) {
        return 0
      }

      const canvas = this.$refs.clipper.clip()
      canvas.toBlob(blob => {
        this.$emit('handle-update-image-src', blob, this.handleCloseModal)
      })
    }
  }
}
</script>
