export default {
  isAvailable(state) {
    return state.isAvailable
  },

  loading(state) {
    return state.loading
  },

  profile(state) {
    return state.profile
  },

  getTwoFa(state) {
    return state.twoFa
  }
}
