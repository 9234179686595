export default p => {
  const { $axios, app } = p

  $axios.onRequest(config => {
    // console.info('Making request to ' + config.url)
  })

  $axios.onError(error => {
    app.$bugsnag.notify(error)

    const code = parseInt(error.response && error.response.status)
    console.error(error.response, code)
  })
}
