export default {
  // socketChat({ commit, state }, chat = {}) {
  //   commit('SOCKET_CHAT', chat)
  // },
  // socketAdminChat({ commit, state }, chat = {}) {
  //   commit('SOCKET_ADMIN_CHAT', chat)
  // },
  // socketMessage({ commit, state }, message = {}) {
  //   commit('SOCKET_MESSAGE', message)
  // },
  // socketAdminMessage({ commit, state }, message = {}) {
  //   commit('SOCKET_ADMIN_MESSAGE', message)
  // },
  // socketTicket({ commit, state }, ticket = {}) {
  //   commit('SOCKET_TICKET', ticket)
  // },
  // socketAdminTicket({ commit, state }, ticket = {}) {
  //   commit('SOCKET_ADMIN_TICKET', ticket)
  // },
  // socketAdminTicketStatus({ commit, state }, ticket = {}) {
  //   commit('SOCKET_ADMIN_TICKET_STATUS', ticket)
  // },
  // socketAdminNewTicket({ commit, state }, ticket = {}) {
  //   commit('SOCKET_ADMIN_NEW_TICKET', ticket)
  // },
  // socketKick({ commit, state }, reason = {}) {
  //   commit('SOCKET_KICK', reason)
  // }
}
