export default function(params) {
  const { store, redirect, route } = params
  if (store.$auth.loggedIn) {
    const {
      user: { role }
    } = store.$auth

    // Redirect to /conversations if basic user is accessing /admin/... or dashboard page
    if (
      (role === '' || role === 'user') &&
      (route.name.match(/admin/i) || route.name.match(/index/i))
    ) {
      redirect('301', '/conversations')
    }

    if (
      role === 'admin' &&
      route.name.match(/my-conversations|resolved|closed/i)
    ) {
      redirect('301', '/admin/conversations')
    }
  }
}
