export default {
    queueList: [],
    customerGroups: [],
    channels: [],
    loading: false,
    actionLoading: false,
    message: '',
    isSuccess: true,
    queueFilters: {},
    agentFilter: {},
    selectedAgents: [],
}