<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :closable="false"
    @cancel="handleResetModal"
    width="50%"
    centered
  >
    <div class="text-center">
      <h1 class="text-xl font-semibold">
        How does this work?
      </h1>
      <p class="text-sm mt-2 mb-5 text-gray mx-20">
        Two-factor authentication adds an extra layer of security to your account by asking for a verication code that we send to your phone when you log in.
      </p>
      <div class="-mx-3 flex">
        <div class="p-3 text-center w-full">
          <img
            :src="step1Icon"
            class="w-full h-auto px-12"
            alt="step 1"
          >
          <p class="mt-4 text-break text-gray">
            Log in using your email and password
          </p>
        </div>
        <div class="p-3 text-center w-full">
          <img
            :src="step2Icon"
            class="w-full h-auto px-12"
            alt="step 2"
          >
          <p class="mt-4 text-break text-gray">
            Using a 2FA app, generates a passcode to verify your identity
          </p>
        </div>
        <div class="p-3 text-center w-full">
          <img
            :src="step3Icon"
            class="w-full h-auto px-12"
            alt="step 3"
          >
          <p class="mt-4 text-break text-gray">
            Once you enter the passcode at the prompt, you'll be logged into your 8x8 Converse portal
          </p>
        </div>
      </div>
      <div class="mt-8">
        <a-button
          @click.prevent="handleResetModal"
          class="mt-5 w-48 mx-auto"
          type="primary"
          size="small"
          >
          Back
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import step1Icon from '../../assets/images/apps/two-fa-step1.svg'
import step2Icon from '../../assets/images/apps/two-fa-step2.svg'
import step3Icon from '../../assets/images/apps/two-fa-step3.svg'

export default {
  name: 'AuthHelpModal',

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      step1Icon,
      step2Icon,
      step3Icon,
    }
  },

  methods: {
    handleResetModal() {
      this.$emit('handle-auth-help-modal', false)
    }
  },
}
</script>

<style lang="scss" scoped>
  .text-break {
    word-break: break-word;
  }
</style>
