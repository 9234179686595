import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']
let store = {}

void (function updateModules() {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/admin-config/actions.js'), 'admin-config/actions.js')
  resolveStoreModules(require('../store/admin-config/getters.js'), 'admin-config/getters.js')
  resolveStoreModules(require('../store/admin-config/mutations.js'), 'admin-config/mutations.js')
  resolveStoreModules(require('../store/admin-config/state.js'), 'admin-config/state.js')
  resolveStoreModules(require('../store/agent-queue/actions.js'), 'agent-queue/actions.js')
  resolveStoreModules(require('../store/agent-queue/getters.js'), 'agent-queue/getters.js')
  resolveStoreModules(require('../store/agent-queue/mutations.js'), 'agent-queue/mutations.js')
  resolveStoreModules(require('../store/agent-queue/state.js'), 'agent-queue/state.js')
  resolveStoreModules(require('../store/agents/actions.js'), 'agents/actions.js')
  resolveStoreModules(require('../store/agents/getters.js'), 'agents/getters.js')
  resolveStoreModules(require('../store/agents/mutations.js'), 'agents/mutations.js')
  resolveStoreModules(require('../store/agents/state.js'), 'agents/state.js')
  resolveStoreModules(require('../store/chat/actions.js'), 'chat/actions.js')
  resolveStoreModules(require('../store/chat/getters.js'), 'chat/getters.js')
  resolveStoreModules(require('../store/chat/mutations.js'), 'chat/mutations.js')
  resolveStoreModules(require('../store/chat/state.js'), 'chat/state.js')
  resolveStoreModules(require('../store/common/actions.js'), 'common/actions.js')
  resolveStoreModules(require('../store/customer/actions.js'), 'customer/actions.js')
  resolveStoreModules(require('../store/customer/getters.js'), 'customer/getters.js')
  resolveStoreModules(require('../store/customer/mutations.js'), 'customer/mutations.js')
  resolveStoreModules(require('../store/customer/state.js'), 'customer/state.js')
  resolveStoreModules(require('../store/layouts/actions.js'), 'layouts/actions.js')
  resolveStoreModules(require('../store/layouts/getters.js'), 'layouts/getters.js')
  resolveStoreModules(require('../store/layouts/mutations.js'), 'layouts/mutations.js')
  resolveStoreModules(require('../store/layouts/state.js'), 'layouts/state.js')
  resolveStoreModules(require('../store/socketio/actions.js'), 'socketio/actions.js')
  resolveStoreModules(require('../store/socketio/getters.js'), 'socketio/getters.js')
  resolveStoreModules(require('../store/socketio/mutations.js'), 'socketio/mutations.js')
  resolveStoreModules(require('../store/socketio/state.js'), 'socketio/state.js')
  resolveStoreModules(require('../store/tickets/actions.js'), 'tickets/actions.js')
  resolveStoreModules(require('../store/tickets/getters.js'), 'tickets/getters.js')
  resolveStoreModules(require('../store/tickets/mutations.js'), 'tickets/mutations.js')
  resolveStoreModules(require('../store/tickets/state.js'), 'tickets/state.js')
  resolveStoreModules(require('../store/user/actions.js'), 'user/actions.js')
  resolveStoreModules(require('../store/user/getters.js'), 'user/getters.js')
  resolveStoreModules(require('../store/user/mutations.js'), 'user/mutations.js')
  resolveStoreModules(require('../store/user/state.js'), 'user/state.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function resolveStoreModules(moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeRoot(moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeState(moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule(moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)
    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function getStoreModule(storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty(storeModule, moduleData, property) {
  if (!moduleData) return

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
