<template>
  <a-modal
    :visible="visible"
    :title="type === 'EDIT_MODE' ? 'Edit security options' : 'Agent Security'"
    :closable="true"
    @cancel="handleResetModal"
    width="50%"
    centered
  >
    <div class="flex space-x-8">
      <div class="flex-grow">
        <template v-if="enabledTwoFa().length">
          <h1 class="text-xl font-semibold">
            <a-icon type="check-circle" class="text-green-500 align-middle mr-2" />2FA has been enabled
          </h1>
          <p class="text-grey mt-1">
            Your account is now protected with one more layer of security
          </p>
        </template>
        <template v-else>
          <h1 class="text-xl font-semibold">
            Enable two-factor authentication (2FA)
          </h1>
          <p class="text-gray mt-1">
            Enable 2FA to protect your account with an extra layer of security
          </p>
        </template>
        <a-row :gutter="8" class="my-12 flex -mx-2">
          <a-col :span="12" class="gutter-row">
            <div class="gutter-box">
              <auth-card
                :image="authAppIcon"
                :data="getAuthData('AUTHENTICATOR_APP')"
                :default-value="authDefaultValue('AUTHENTICATOR_APP')"
                :name="'Authenticator App'"
                :description="'Use an authentication app (such as Google Authenticator, Lastpass, or MS Authenticator) to generate time-sensitive verification codes on your smartphone.'"
                @handle-show-auth-google="handleShowAuthGoogle"
                @handle-show-auth-remove="handleShowAuthRemove"
                @set-default="setDefault"
                recommend
              />
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-between">
        <a-button
          v-if="!enabledTwoFa().length"
          @click.prevent="isShowAuthHelpModal = true"
          type="link"
        >
          How does 2FA work?
        </a-button>
        <a-button
          v-else-if="enabledTwoFa().length >= twoFa.length"
          @click="removeAllAuth"
          class="mt-2"
          type="danger"
          plain
        >
          Remove all 2FA
        </a-button>
      </div>
    </template>
    <auth-help-modal
      :visible="isShowAuthHelpModal"
      @handle-auth-help-modal="handleShowAuthHelp"
    />
    <auth-google-modal
      :visible="isShowAuthGoogleModal"
      :updating="verificationLoading"
      :generate-qr="getTwoFaQrCode"
      @handle-auth-google-modal="handleShowAuthGoogle"
      @submit="submitAuthCode"
    />
    <auth-remove-app-modal
      :visible="isShowAuthRemoveModal"
      @handle-auth-remove-modal="handleShowAuthRemove"
      @remove-auth="removeAuth"
    />
  </a-modal>
</template>

<script>
  import moment from 'moment'
  import { mapActions } from 'vuex'
  import authAppIcon from '../../assets/images/apps/two-fa-authapp.svg'
  import AuthCard from './auth-card.vue'
  import AuthHelpModal from './auth-help-modal.vue'
  import AuthGoogleModal from './auth-google-modal.vue'
  import AuthRemoveAppModal from './auth-remove-app-modal.vue'
  import agentRoles from '@/json/agentRoles.json'

  export default {
    name: 'SecurityModal',

    components: {
      AuthCard,
      AuthHelpModal,
      AuthGoogleModal,
      AuthRemoveAppModal
    },

    mixins: [],

    props: {
      visible: {
        type: Boolean,
        default: false
      },
      twoFa: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: 'EDIT_MODE',
        validator: value => {
          return ['EDIT_MODE', 'VIEW_MODE'].includes((value || '').toUpperCase())
        }
      }
    },

    data() {
      return {
        authAppIcon,
        defaultAuth: '',
        moment,
        agentRoles,
        verificationLoading: false,
        isFormChanged: false,
        isShowAuthHelpModal: false,
        isShowAuthGoogleModal: false,
        isShowAuthRemoveModal: false,
        updating: false
      }
    },

    watch: {
      twoFa() {
        const twoFaAuth = this.twoFa.find(d => d.default)
        if (twoFaAuth) {
          this.defaultAuth = twoFaAuth.type
        }
      }
    },

    created() {
      this.updating = true
      this.fetchData()
    },

    methods: {
      ...mapActions({
        enableTwoFa: 'user/enableTwoFa',
        updateTwoFA: 'user/updateTwoFA',
        removeAllTwoFa: 'user/removeAllTwoFa',
        removeTwoFa: 'user/removeTwoFa',
        setDefaultTwoFa: 'user/setDefaultTwoFa',
        getTwoFaQrCode: 'user/getTwoFaQrCode'
      }),

      handleResetModal() {
        this.$emit('handle-security-modal', false)
      },

      handleShowAuthHelp(bool) {
        this.isShowAuthHelpModal = bool
      },

      handleShowAuthGoogle(bool) {
        this.isShowAuthGoogleModal = bool
      },

      handleShowAuthRemove(bool) {
        this.isShowAuthRemoveModal = bool
      },

      fetchData() {
        this.updateTwoFA()
          .then(() => {
            const twoFaAuth = this.twoFa.find(d => d.default)
            this.defaultAuth = (twoFaAuth && twoFaAuth.type) || null
          })
          .catch((err) => {
            this.$message.error(err)
          })
          .finally(() => {
            this.loading = false
          })
      },

      setDefault(p) {
        if (p.type !== this.defaultAuth) {
          const oldDefaultAuth = this.defaultAuth
          this.defaultAuth = p.type
          const payload = { type: p.type === 'AUTHENTICATOR_APP' ? 'sms' : 'app' }
          this.setDefaultTwoFa(payload)
            .then(() => {
              this.fetchData()
            })
            .catch((err) => {
              this.defaultAuth = oldDefaultAuth
              this.$message.error(err)
            })
        }
      },

      authDefaultValue(type) {
        return this.defaultAuth === type
      },

      enabledTwoFa() {
        return this.twoFa.filter(d => d.enabled)
      },

      enabledSMSTwoFa() {
        return this.twoFa.filter(d => (d.enabled && d.type === 'SMS_OTP'))
      },

      submitAuthCode(p) {
        this.verificationLoading = true
        const payload = { ...p, remember: false }
        this.enableTwoFa(payload)
          .then((res) => {
            this.modalScreen = null
            this.validationStep = 1
            this.updateTwoFA()

            // Stop showing suggest 2FA enable once user enables an 2FA
            localStorage.setItem('hideSuggestEnableTwoFa', true)

            this.handleShowAuthGoogle(false)
          })
          .catch((err) => {
            if (err.response.status >= 400 && err.response.status < 500) {
              this.$message.error('Invalid authentication code.' || err.response.data.error)
            } else {
              this.$message.error('Failed to submit the authentication code.')
            }
          })
          .finally(() => {
            this.verificationLoading = false
          })
      },

      removeAuth(payload) {
        this.removeTwoFa(payload)
          .then(() => {
            this.fetchData()
            this.handleShowAuthRemove(false)
          }).catch((err) => {
            if (err.response.data.error === undefined) {
              this.$message.error("Sorry for inconvenience. Your request can't be processed at the moment.")
            } else {
              this.$message.warning(err.response.data.error)
            }
          }).finally(() => {
            this.updating = false
          })
      },

      removeAllAuth() {
        this.$prompt('Please input your password', 'Remove all two-factor authentication?', {
          confirmButtonText: 'Yes, remove all 2FA',
          cancelButtonText: 'Cancel',
          inputPattern: /^(?!\s*$).+/,
          inputErrorMessage: 'Please enter a valid password',
          inputType: 'password',
        }).then(({ value }) => {
          this.removeAllTwoFa({ password: value })
            .then(() => {
              this.fetchData()
            }).catch((err) => {
              this.$message.error(err)
            })
        }).catch(() => {})
      },

      getAuthData(type) {
        return this.twoFa.find(a => a.type.toLowerCase() === type.toLowerCase())
      },
    }
  }
</script>
