export default () => ({
  message: [],
  adminMessage: [],
  ticket: [],
  adminTicket: [],
  adminTicketStatus: [],
  adminNewTicket: [],
  chat: [],
  adminChat: []
})
