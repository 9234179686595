export default {
  SET_AVAILABILITY(state, bool) {
    state.isAvailable = bool
  },

  SET_LOADING(state, bool) {
    state.loading = bool
  },

  SET_PROFILE(state, data) {
    state.profile = { ...state.profile, ...data }
  },

  SET_TWO_FA(state, payload) {
    state.twoFa = payload
  }
}
