export default () => ({
  all: [],
  tickets: [],
  selectedTicket: {},
  loading: false,
  allLoading: false,
  reasons: [],
  ticketHistory: [],
  allHistory: [],
  historyLoading: false,
  reasonsLoading: false,
  statusUpdateLoading: false,
  resolvedTicketsTotal: 0,
  myTicketsTotal: 0,
  closedTicketsTotal: 0,
  allTicketsTotal: 0,
  subFilters: {},
  stats: {},
  statsFilter: {
    from: '',
    to: '',
    agentId: ''
  },
  pagination: {
    pageDir: 1,
    cursor: '',
    currentPage: 1,
    cursorOption: [],
    limit: 7
  }
})
