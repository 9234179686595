export default {
  SET_LOADING(state, loading) {
    state.loading = loading
  },

  SET_SAVE_LOADING(state, loading) {
    state.saveLoading = loading
  },

  SET_CONFIG_DATA(state, data) {
    state.configData = data
  }
}
