<template>
  <div
    :class="[{'card--recommend': !enabled && recommend}]"
    class="card border border-solid bg-white p-8 relative h-full"
  >
    <span
      v-if="!enabled && recommend"
      class="card__tag card__tag--recommend tracking-wide"
    >
      RECOMMENDED
    </span>
    <span
      v-else-if="enabled"
      class="card__tag card__tag--enabled tracking-wide"
    >
      ENABLED
    </span>

    <div
      :class="[{'card__image--enabled': enabled}]"
      class="card__image p-2 text-blue mr-8"
    >
      <img
        :src="image"
        alt="8x8"
      >
    </div>
    <div class="w-2/3 flex flex-col justify-between h-full">
      <div>
        <p class="text-lg text-black">
          {{ name }}
        </p>
        <p
          v-if="!enabled"
          class="text-gray-dark text-sm mt-3"
        >
          {{ description }}
        </p>
        <div v-else>
          <p
            v-if="false"
            class="text-gray-dark text-xs mt-2"
          >
            Enabled on {{ formatDate('11/19/2019') }}
          </p>
          <a-switch
            :checked="true"
            :checked-children="'default'"
            class="mt-3 mb-20"
            default-checked
          />
        </div>
      </div>
      <div
        v-if="type === 'EDIT_MODE'"
        class="mt-5"
      >
        <a-button
          v-if="!enabled"
          @click.prevent="handleAuthGoogleModal"
          class="w-full"
          type="primary"
        >
          Enable
        </a-button>
        <a-button
          v-else
          @click="handleAuthRemoveModal"
          class="w-full mt-15"
          type="danger"
        >
          Remove
        </a-button>
      </div>
      <div
        v-else
        class="mt-5"
      >
        <p
          v-if="!enabled"
          class="text-red-500 text-center font-bold w-full"
        >
          Disabled
        </p>
        <p
          v-else
          class="text-green-500 text-center font-bold w-full mt-15"
        >
          Enabled
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import Moment from 'moment'
  import { mapActions } from 'vuex'

  export default {
    name: 'AuthCard',

    props: {
      type: {
        type: String,
        default: 'EDIT_MODE',
        validator: value => {
          return ['EDIT_MODE', 'VIEW_MODE'].includes((value || '').toUpperCase())
        }
      },
      data: {
        type: Object,
        default: () => {},
      },
      name: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      recommend: {
        type: Boolean,
        default: false,
      },
      image: {
        type: String,
        default: '',
      }
    },

    data() {
      return {
        validationStep: 1,
        defaultValue: true,
        internalAuthGoogleModal: false,
        isShowAuthGoogleModal: false,
        isShowAuthRemoveModal: false,
        updating: false
      }
    },

    computed: {
      enabled: function() {
        return this.data && this.data.enabled
      }
    },

    created() {
      this.defaultValue = this.data ? this.data.default : true
    },

    methods: {
      ...mapActions({
        enableTwoFa: 'user/enableTwoFa',
        updateTwoFA: 'user/updateTwoFA',
        getTwoFaQrCode: 'user/getTwoFaQrCode'
      }),

      handleAuthGoogleModal() {
        this.$emit('handle-show-auth-google', true)
      },

      handleAuthRemoveModal() {
        this.$emit('handle-show-auth-remove', true)
      },
    
      getAuthName(authName) {
        let name = authName
        switch (authName) {
            case 'AUTHENTICATOR_APP':
                name = 'Google'
                break
            case 'SMS_OTP':
                name = 'SMS'
                break
            default:
                break
        }
        return name
      },

      remove() {
        this.$emit('remove')
      },

      toggleDefault() {
        const payload = { type: this.data.type }
        this.$emit('set-default', payload)
      },

      formatDate(dt) {
        return Moment(dt).format('DD MMM YYYY')
      },

      updateValidationStep(n) {
        this.validationStep = n
      }
    }
  }
</script>

<style lang="scss" scoped>
  .card {
    display: flex;
    align-items: center;
    word-break: break-word;
    border-color: #EFF3FF;
    transition: border 0.1s ease-in,
                transform 0.1s ease-in;

    &:hover {
      border-color: #DCEAFD;
      transform: translateY(-2px);

      & .card__image {
        filter: grayscale(0%);
      }
    }

    &--tag {
      box-shadow: 0px 0px 5px 0px rgba(220,234,253,1);
    }

    &__tag {
      line-height: 1;
      font-size: 11px;
      padding: 8px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      position: absolute;
      top: 10px;
      left: 0;

      &--recommend {
        color: #61A0F7;
        background-color: #DCEAFD;
      }

      &--enabled {
        color: #84D0A0;
        background-color: #E0F4E8;
      }
    }

    &__image {
      width: 60px;
      filter: grayscale(100%);
      transition: filter 0.1s ease-in;

      &--enabled {
        filter: grayscale(0%);
      }
    }
  }
</style>
